import React, { useEffect, useState } from "react";
import { 
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { CheckCircle, RadioButtonCheckedOutlined, RadioButtonUncheckedOutlined } from '@material-ui/icons';
import clsx from "clsx";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  //  ********************
  //  ** Main Container **
  //  ********************
  pollContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    gap: "2px",
    padding: "3px",
  },



  //  *********************
  //  ** Deleted Message **
  //  *********************
  contentItemDeleted: { fontStyle: "italic", color: "rgba(0, 0, 0, 0.36)", },



  //  ********************
  //  ** Type Container **
  //  ********************
  //
  // - multiple answers
  // - single answer
  //
  allowAnswersContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap", 
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1em",
    gap: "2px",
    color: "#998",
  },

  multipleAnswersContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },



  //  ***********************
  //  ** Options Container **
  //  ***********************
  optionsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    gap: "2px",
    marginTop: "1em",
    width: "100%",
  },

  optionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    width: "100%",
  },



  //  ******************************
  //  ** Votes Progress Container **
  //  ******************************
  votesProgressContainer: {
    display: "relative",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },

  votesProgressAvatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "all 0.3s ease",
    "&:hover": { transform: "translateY(-5px)", },
  },

  votesProgressAvatarContainerSkeleton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  votesProgressAvatar: { width: "20px", height: "20px", },



  //  **********************
  //  ** View Vote Button **
  //  **********************
  viewVoteButton: { color: theme.palette.secondary.ackCheck, },
}));

const PollPreview = ({ message, handleOpenPollVotesModal, readonly=false }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();

  const [frequencyVotes, setFrequencyVotes] = useState([]);
  const [optionsChecked, setOptionsChecked] = useState([]);
  const [totalVotes, setTotalVotes] = useState(0);

  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    const delayDebounceFN = setTimeout(() => {
      const fetchOptionsAndVotes = () => {
        // ***---- Options ----***
        setOptionsChecked(message.pollsOptions.map((option, index) => (
          { localId: index, flag: option.pollsVotes.length > 0 }
        )));
        
        /* ***---- Options Checked ----***
         *
         * 
         * [
         *  { localId: 0, flag: false },
         *  { localId: 1, flag: true },
         *  { localId: 2, flag: false },
         *  { localId: 3, flag: false },
         *  { localId: 4, flag: false }
         * ]
         * 
        */
        setOptionsChecked(message.pollsOptions.map((option, index) => (
          { localId: index, flag: option.pollsVotes.some(vote => vote.fromMe) }
        )));

        /* ***---- Votes ----***
         *
         * 
         * [
         *  { localId: 0, votes: 0 },
         *  { localId: 1, votes: 1 },
         *  { localId: 2, votes: 2 },
         *  { localId: 3, votes: 0 },
         *  { localId: 4, votes: 1 }
         * ]
         * 
        */
        let tempTotalVotes = 0;

        setFrequencyVotes(message.pollsOptions.map((option, index) => {
          tempTotalVotes += option.pollsVotes.length;
          return { localId: index, votes: option.pollsVotes.length }
        }));

        setTotalVotes(tempTotalVotes);
      };
      fetchOptionsAndVotes();
    }, 500);

    return () => clearTimeout(delayDebounceFN);
  }, [message]);



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
    {/* ***---- Render Options ----*** */}
    {optionsChecked.length > 0 && (
      <div className={clsx(classes.pollContainer, {[classes.contentItemDeleted]: message.isDeleted,})}>
        <b>📊 {message.body}</b>

        {message.allowMultipleAnswers
          ? (
            <div className={classes.allowAnswersContainer}>
              <span className={classes.multipleAnswersContainer}>
                <CheckCircle style={{ fontSize: "1em" }} />
                <CheckCircle style={{ fontSize: "1em" }} />
              </span>
              {i18n.t("pollPreview.multipleAnswers")}
            </div>
          )
          : (
            <div className={classes.allowAnswersContainer}>
              <CheckCircle style={{ fontSize: "1em" }} />
              {i18n.t("pollPreview.singleAnswer")}
            </div>
          )
        }

        <div className={classes.optionsContainer}>
          {message.pollsOptions.sort((option1, option2) => option1.localId - option2.localId).map(option => (
            <div className={classes.optionContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={`pollOption-${option.messageId}-${option.localId}`}
                    key={option.id}
                    icon={<RadioButtonUncheckedOutlined style={{ fontSize: "0.90em" }} />}
                    checkedIcon={<RadioButtonCheckedOutlined style={{ fontSize: "0.90em" }} />}
                    checked={optionsChecked[option.localId].flag}
                    disabled={message.isDeleted || readonly}
                  />
                }
                label={<span style={{ fontSize: "0.90em" }}>{option.description}</span>}
              />

              <Grid className={classes.votesProgressContainer} spacing={1} container>
                <Grid xs={9} item>
                  <LinearProgress 
                    value={frequencyVotes[option.localId]?.votes * 100 / totalVotes || 0}
                    variant="determinate"
                    style={message.isDeleted ? {backgroundColor:"rgba(0, 0, 0, 0.36)"} : {}}
                  />
                </Grid>

                <Grid xs={3} item className={message.isDeleted ? classes.votesProgressAvatarContainerSkeleton : classes.votesProgressAvatarContainer}>
                  <Tooltip title={i18n.t("pollPreview.viewVotes")} placement="top-start" arrow>
                    <Avatar className={classes.votesProgressAvatar} onClick={() => { !message.isDeleted && handleOpenPollVotesModal(message)}}>
                      {frequencyVotes[option.localId]?.votes || 0}
                    </Avatar>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
          ))}

          <br />

          <Button
            fullWidth
            color="inherit"
            className={classes.viewVoteButton}
            disabled={message.isDeleted}
            onClick={() => handleOpenPollVotesModal(message)}
          >
            {i18n.t("pollPreview.viewVotes")}
          </Button>
        </div>
      </div>
    )}

    {/* ***---- Render Skeleton ----*** */}
    {optionsChecked.length <= 0 && (
      <div className={classes.pollContainer}>
        <Skeleton animation="wave" height={25} width={90} />

        <div className={classes.allowAnswersContainer}>
          <Skeleton animation="wave" height={25} width={90} />
        </div> 

        <div className={classes.optionsContainer}>
          {message.pollsOptions.map(() => (
            <div className={classes.optionContainer}>
              <FormControlLabel
                control={<Checkbox icon={<RadioButtonUncheckedOutlined style={{ fontSize: "0.90em" }} />} disabled />}
                label={<Skeleton animation="wave" height={25} width={90} />}
              />

              <Grid className={classes.votesProgressContainer} spacing={1} container>
                <Grid xs={9} item>
                  <LinearProgress value={0} variant="determinate" />
                </Grid>

                <Grid xs={3} item className={classes.votesProgressAvatarContainerSkeleton}>
                  <Avatar style={{ width: "20px", height: "20px" }}>0</Avatar>
                </Grid>
              </Grid>
            </div>
          ))}
          
          <br />

          <Button fullWidth color="inherit" className={classes.viewVoteButton} disabled>
            {i18n.t("pollPreview.viewVotes")}
          </Button>
        </div>
      </div>
    )}
    </>
  );
};

export default PollPreview;