import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";


import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import AutomaticMessagesKeywordsModal from "../AutomaticMessagesKeywordsModal";
import AutomaticMessagesMediasModal from "../AutomaticMessagesMediasModal";
import toastError from "../../errors/toastError";
import WhatsappSelectModals from "../WhatsappSelectModals";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "wrap", },

  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },

  btnWrapper: { position: "relative", },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50.00%",
    left: "50.00%",
    marginTop: -12,
    marginLeft: -12,
  },

  floatingButton: {
    transition: "transform 0.30s",
    "&:hover": { transform: "translateY(-5px)", },
  },
}));

const AutomaticMessagesModal = ({ open, onClose, automaticMessageId }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();

  const initialState = {
    name: "",
    body: "",
    mainKeyword: "",
    alternativeKeywords: [],
    medias: [],
    whatsappIds: [],
    keywordsIdsNames: [],
  };

  const [automaticMessage, setAutomaticMessage] = useState(initialState);
  const [nonUniqueKeywordsArray, setNonUniqueKeywordsArray] = useState([]);

  const [selectedWhatsappIds, setSelectedWhatsappIds] = useState([]);
  const [keywordsModalOpen, setKeywordsModalOpen] = useState(false);
  const [mediasModalOpen, setMediasModalOpen] = useState(false);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const fetchMedia = async (mediaUrl) => {
      const { data, headers } = await api.get(mediaUrl, { responseType: "blob", });

      const contentType = headers["content-type"] || "application/octet-stream";
      const blob = new Blob([data], { type: contentType });

      const fileName = mediaUrl.split("/automaticMessages/")[1];
      const file = new File([blob], fileName, { type: contentType });

      return file;
    };

    const fetchAutomaticMessage = async () => {
      if (!open) return;
      if (!automaticMessageId) return;

      try {
        // ***---- Fetching Automatic Message Data ----***
        const { data } = await api.get(`/automaticMessages/${automaticMessageId}`);

        // ***---- Transforming WhatsappIds ----***
        const whatsappIds = data.whatsappAutomaticMessages.map(whatsapp => whatsapp.whatsappId);
        setSelectedWhatsappIds(whatsappIds);

        // ***---- Transforming Automatic Message Data ----***
        const mediaPaths = data.automaticMessageMediaPaths.map(mediaPath => mediaPath.mediaPath);

        const medias = await Promise.all(
          mediaPaths.map(async (mediaPath) => {
            const fetchedMedia = await fetchMedia(mediaPath);
            return fetchedMedia;
          })
        );

        // ***---- Transforming Automatic Message Keywords Ids and Names Data ----***
        const keywordsIdsNames = data.automaticMessageKeywords.map(keyword => {
          return { id: keyword.id, name: keyword.keyword };
        });

        const transformedAutomaticMessage = {
          id: data.id,
          name: data.name,
          body: data.body,
          mainKeyword: data.automaticMessageKeywords.filter(keyword => keyword.isMainKeyword)?.[0]?.keyword,
          alternativeKeywords: data.automaticMessageKeywords.filter(keyword => !keyword.isMainKeyword).map(keyword => keyword.keyword),
          medias,
          whatsappIds,
          keywordsIdsNames,
        };

        // ***---- Setting State ----***
        setAutomaticMessage(transformedAutomaticMessage);
      } catch (exception) {
        console.log("AutomaticMessagesModal Use Effect 1 Exception:", exception);
        toastError(exception);
      }
    };

    fetchAutomaticMessage();
  }, [open, automaticMessageId]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setAutomaticMessage(initialState);
      setSelectedWhatsappIds([]);
      setNonUniqueKeywordsArray([]);
    }, 100);
  };

  const handleOpenKeywordsModal = () => {
    setKeywordsModalOpen(true);
  };

  const handleCloseKeywordsModal = () => {
    setKeywordsModalOpen(false);
  };

  const handleOpenMediasModal = () => {
    setMediasModalOpen(true);
  };

  const handleCloseMediasModal = () => {
    setMediasModalOpen(false);
  };

  const formatKeywordsIdsNames = (values) => {
    const finalKeywords = new Set([values.mainKeyword, ...values.alternativeKeywords]);

    const finalKeywordsIdsNames = values.keywordsIdsNames.filter(
      element => finalKeywords.has(element.name)
    );

    finalKeywords.forEach(name => {
      if (!finalKeywordsIdsNames.some(element => element.name === name)) {
        finalKeywordsIdsNames.push({ id: null, name });
      }
    });

    return finalKeywordsIdsNames;
  };

  const validateAutomaticMessage = async (values) => {
    let areValuesValid = true;

    //  ****************
    //  ** Main Modal **
    //  ****************
    values.name = values.name.trim();
    values.body = values.body.trim();

    if (values.name.length < 3 || values.name.length > 25) {
      areValuesValid = false;
      toast.info(i18n.t("automaticMessagesModal.validations.name"));
    }

    if (values.body.length < 7 || values.body.length > 500) {
      areValuesValid = false;
      toast.info(i18n.t("automaticMessagesModal.validations.body"));
    }

    if (values.whatsappIds.length === 0) {
      areValuesValid = false;
      toast.info(i18n.t("automaticMessagesModal.validations.whatsappIds"));
    }

    //  ********************
    //  ** Keywords Modal **
    //  ********************
    if (values.mainKeyword === "") {
      areValuesValid = false;
      toast.info(i18n.t("automaticMessagesKeywordsModal.validations.mainKeyword"));
      setKeywordsModalOpen(true);
    }

    //  ****************************
    //  ** Backend - Name Unicity **
    //  ****************************
    if (areValuesValid) {
      const { data: { nameUnicityValidation } } = await api.get("/automaticMessagesValidations/nameUnicity", {
        params: { automaticMessageId, name: values.name }
      });
  
      if (!nameUnicityValidation) {
        areValuesValid = false;
        toast.info(i18n.t("automaticMessagesModal.validations.nameUnicity"));
  
        const automaticMessagesName = document.getElementById("automaticMessagesName");
        automaticMessagesName.focus();
      }
    }

    //  ********************************
    //  ** Backend - Keywords Unicity **
    //  ********************************
    if (areValuesValid) {
      const keywordsIdsNames = formatKeywordsIdsNames(values);
  
      const { data: { keywordsUnicityValidation, nonUniqueKeywords } } = await api.get("/automaticMessagesValidations/keywordsUnicity", {
        params: { keywordsIdsNames }
      });

      if (!keywordsUnicityValidation) {
        areValuesValid = false;
        toast.info(i18n.t("automaticMessagesModal.validations.keywordsUnicity"));
        setNonUniqueKeywordsArray(nonUniqueKeywords);
        setKeywordsModalOpen(true);
      }
    }

    //  ************
    //  ** Return **
    //  ************
    return areValuesValid;
  };

  const handleSaveAutomaticMessage = async (values) => {
    values.whatsappIds = selectedWhatsappIds;

    const isAutomaticMessageDataValid = await validateAutomaticMessage(values);

    if (isAutomaticMessageDataValid) {
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("body", values.body);
        formData.append("mainKeyword", values.mainKeyword);
        values.alternativeKeywords.forEach(alternativeKeyword => formData.append("alternativeKeywords", alternativeKeyword));
        values.whatsappIds.forEach(whatsappId => formData.append("whatsappIds", whatsappId));
        values.medias.forEach(file => formData.append("medias", file));

        if (automaticMessageId) await api.put(`/automaticMessages/${automaticMessageId}`, formData);
        else await api.post("/automaticMessages", formData);

        handleClose();
      } catch (exception) {
        console.log("Handle Save Automatic Message Exception:", exception);
        toastError(exception);
      }
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" scroll="paper" fullWidth>
        <DialogTitle>
          {automaticMessageId ? i18n.t("automaticMessagesModal.title.edit") : i18n.t("automaticMessagesModal.title.add")}
        </DialogTitle>

        <Formik
          initialValues={automaticMessage}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setTimeout(async () => {
              await handleSaveAutomaticMessage(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                {/* 
                  ************
                  ** Modals **
                  ************
                */}
                <AutomaticMessagesKeywordsModal
                  open={keywordsModalOpen}
                  onClose={handleCloseKeywordsModal}
                  nonUniqueKeywordsArray={nonUniqueKeywordsArray}
                  setNonUniqueKeywordsArray={setNonUniqueKeywordsArray}
                />

                <AutomaticMessagesMediasModal open={mediasModalOpen} onClose={handleCloseMediasModal} />



                {/* 
                  **********
                  ** Name **
                  **********
                */}
                <Field
                  id="automaticMessagesName"
                  as={TextField}
                  label={i18n.t("automaticMessagesModal.form.name")}
                  autoFocus
                  autoComplete="off"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  required

                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}

                  InputProps={{ inputProps: { minLength: 3, maxLength: 25 } }}
                />



                {/* 
                  **********
                  ** Body **
                  **********
                */}
                <Field
                  as={TextField}
                  label={i18n.t("automaticMessagesModal.form.body")}
                  autoComplete="off"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  required

                  multiline
                  minRows={5}
                  maxRows={5}

                  name="body"
                  error={touched.body && Boolean(errors.body)}
                  helperText={touched.body && errors.body}

                  InputProps={{ inputProps: { minLength: 7, maxLength: 500 } }}
                />

                {/* 
                  ***************
                  ** Whatsapps **
                  ***************
                */}
                <WhatsappSelectModals
                  selectedWhatsappIds={selectedWhatsappIds}
                  onChange={(values) => setSelectedWhatsappIds(values)}
                />

                {/* 
                  *************************
                  ** Keywords and Medias **
                  *************************
                */}
                <br />
                <Divider />
                <br />

                <div className={classes.buttonsContainer}>
                  <Button
                    onClick={handleOpenKeywordsModal}
                    color="inherit"
                    disabled={isSubmitting}
                    variant="outlined"
                    className={classes.floatingButton}
                  >
                    {i18n.t("automaticMessagesModal.form.buttons.keywords")}
                  </Button>

                  <Button
                    onClick={handleOpenMediasModal}
                    color="inherit"
                    disabled={isSubmitting}
                    variant="outlined"
                    className={classes.floatingButton}
                  >
                    {i18n.t("automaticMessagesModal.form.buttons.medias")}
                  </Button>
                </div>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="inherit"
                  disabled={isSubmitting}
                  variant="outlined"
                  className={classes.floatingButton}
                >
                  {i18n.t("automaticMessagesModal.buttons.cancel")}
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={`${classes.btnWrapper} ${classes.floatingButton}`}
                >
                  {automaticMessageId ? i18n.t("automaticMessagesModal.buttons.okEdit") : i18n.t("automaticMessagesModal.buttons.okAdd")}
                  {isSubmitting && (<CircularProgress size={24} className={classes.buttonProgress} />)}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default AutomaticMessagesModal;
