import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import AutomaticMessagesModal from "../../components/AutomaticMessagesModal";
import AutomaticMessagesTableCellModal from "../../components/AutomaticMessagesTableCellModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import SocketAutomaticMessages from "../../context/Socket/Listeners/SocketAutomaticMessages";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import truncateString from "../../utils/truncateString";

const reducer = (state, action) => {
  if (action.type === "LOAD_AUTOMATIC_MESSAGES") {
    const automaticMessages = action.payload;
    const newAutomaticMessages = [];

    automaticMessages.forEach((automaticMessage) => {
      const automaticMessageIndex = state.findIndex((am) => am.id === automaticMessage.id);
      if (automaticMessageIndex !== -1) state[automaticMessageIndex] = automaticMessage;
      else newAutomaticMessages.push(automaticMessage);
    });

    return [...state, ...newAutomaticMessages];
  }

  if (action.type === "UPDATE_AUTOMATIC_MESSAGES") {
    const automaticMessage = action.payload;
    const automaticMessageIndex = state.findIndex((am) => am.id === automaticMessage.id);

    if (automaticMessageIndex !== -1) {
      state[automaticMessageIndex] = automaticMessage;
      return [...state];
    }
    else {
      return [automaticMessage, ...state];
    }
  }

  if (action.type === "DELETE_AUTOMATIC_MESSAGE") {
    const automaticMessageId = action.payload;
    const automaticMessageIndex = state.findIndex((am) => am.id === automaticMessageId);
    if (automaticMessageIndex !== -1) state.splice(automaticMessageIndex, 1);
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  interactiveTableCell: { cursor: "pointer", },

  icon: { color: theme.palette.text.primary, },

  floatingButton: {
    transition: "transform 0.30s",
    "&:hover": { transform: "translateY(-5px)", },
  },

  actionButton: {
    "&:hover": { color: theme.palette.primary.main, },
  },
}));

const AutomaticMessages = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [automaticMessages, dispatch] = useReducer(reducer, []);

  const [selectedAutomaticMessage, setSelectedAutomaticMessage] = useState(null);
  const [automaticMessageModalOpen, setAutomaticMessageModalOpen] = useState(false);
  const [deletingAutomaticMessage, setDeletingAutomaticMessage] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [automaticMessagesTableCellModalOpen, setAutomaticMessagesTableCellModalOpen] = useState(false);
  const [selectedTableCellModal, setSelectedTableCellModal] = useState("");
  const [selectedTableTextModal, setSelectedTableTextModal] = useState("");



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchAutomaticMessages = async () => {
        try {
          const { data } = await api.get("/automaticMessages", { params: { searchParam, pageNumber } });

          const transformedAutomaticMessages = [];

          data.automaticMessages.forEach(automaticMessage => {
            transformedAutomaticMessages.push({
              id: automaticMessage.id,
              name: automaticMessage.name,
              body: automaticMessage.body,
              keywords: automaticMessage.automaticMessageKeywords
                .map(keyword => keyword.keyword)
                .join(", "),
            });
          });


          dispatch({ type: "LOAD_AUTOMATIC_MESSAGES", payload: transformedAutomaticMessages });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (exception) {
          console.log("Automatic Messages Use Effect 1 Exception:", exception);
          toastError(exception);
        }
      };

      fetchAutomaticMessages();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleSearchParam = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenAutomaticMessageModal = () => {
    setSelectedAutomaticMessage(null);
    setAutomaticMessageModalOpen(true);
  };

  const handleCloseAutomaticMessageModal = () => {
    setSelectedAutomaticMessage(null);
    setAutomaticMessageModalOpen(false);
  };

  const handleOpenAutomaticMessagesTableCellModal = (tableCell, tableText) => {
    setAutomaticMessagesTableCellModalOpen(true);
    setSelectedTableCellModal(tableCell);
    setSelectedTableTextModal(tableText);
  };

  const handleCloseAutomaticMessagesTableCellModal = () => {
    setAutomaticMessagesTableCellModalOpen(false);
    setSelectedTableCellModal("");
    setSelectedTableTextModal("");
  };

  const handleEditAutomaticMessage = (automaticMessage) => {
    setSelectedAutomaticMessage(automaticMessage);
    setAutomaticMessageModalOpen(true);
  };

  const handleDeleteAutomaticMessage = async (automaticMessageId) => {
    try {
      await api.delete(`/automaticMessages/${automaticMessageId}`);
    } catch (exception) {
      console.log("Handle Delete Automatic Message Exception:", exception);
      toastError(exception);
    }

    setDeletingAutomaticMessage(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleScroll = (event) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) setPageNumber((previousValue) => previousValue + 1);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <MainContainer>
      {/* 
        ************
        ** Socket **
        ************
      */}
      <SocketAutomaticMessages dispatch={dispatch} />

      {/* 
        ************
        ** Modals **
        ************
      */}
      <ConfirmationModal
        title={i18n.t("automaticMessages.confirmationModal.deleteTitle")}
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteAutomaticMessage(deletingAutomaticMessage.id)}
      />

      <AutomaticMessagesModal
        open={automaticMessageModalOpen}
        onClose={handleCloseAutomaticMessageModal}
        automaticMessageId={selectedAutomaticMessage && selectedAutomaticMessage.id}
      />

      <AutomaticMessagesTableCellModal
        open={automaticMessagesTableCellModalOpen}
        onClose={handleCloseAutomaticMessagesTableCellModal}
        selectedTableText={selectedTableTextModal}
        selectedTableCell={selectedTableCellModal}
      />



      {/* 
        ************
        ** Header **
        ************
      */}
      <MainHeader>
        <Title>{i18n.t("automaticMessages.title")}</Title>

        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("automaticMessages.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearchParam}
            InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon style={{ color:"gray" }} /></InputAdornment>), }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAutomaticMessageModal}
            className={classes.floatingButton}
          >
            {i18n.t("automaticMessages.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>



      {/* 
        ***********
        ** Paper **
        ***********
      */}
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{i18n.t("automaticMessages.table.name")}</TableCell>
              <TableCell align="center">{i18n.t("automaticMessages.table.keywords")}</TableCell>
              <TableCell align="center">{i18n.t("automaticMessages.table.body")}</TableCell>
              <TableCell align="center">{i18n.t("automaticMessages.table.actions")}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <>
              {automaticMessages.map((automaticMessage) => (
                <TableRow key={automaticMessage.id}>
                  <TableCell align="center">{automaticMessage.name}</TableCell>

                  <TableCell
                    align="center"
                    className={classes.interactiveTableCell}
                    onClick={() => handleOpenAutomaticMessagesTableCellModal("keywords", automaticMessage.keywords)}
                  >
                    {truncateString(automaticMessage.keywords, 30)}
                  </TableCell>

                  <TableCell
                    align="center"
                    className={classes.interactiveTableCell}
                    onClick={() => handleOpenAutomaticMessagesTableCellModal("body", automaticMessage.body)}
                  >
                    {truncateString(automaticMessage.body, 30)}
                  </TableCell>

                  <TableCell align="center">
                    <IconButton
                      className={classes.icon}
                      size="small"
                      onClick={() => handleEditAutomaticMessage(automaticMessage)}
                    >
                      <EditIcon className={classes.actionButton} />
                    </IconButton>

                    <IconButton
                      className={classes.icon}
                      size="small"
                      onClick={() => {
                        setConfirmModalOpen(true);
                        setDeletingAutomaticMessage(automaticMessage);
                      }}
                    >
                      <DeleteOutlineIcon className={classes.actionButton} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}

              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default AutomaticMessages;