const messages = {
  en: {
    translations: {  
      languagesMenu: {
        language1: 'Português',
        language2: 'English',
        language3: 'Español',
      },
      modalImage: {
        openNewTab: "Open image in a new tab.",
      },
      page404: {
        message: "It looks like you got lost. Tap on one of the menu options on the left side to get back o the page!",
      },
      messagesListLabels: {
        ticketEnd: "End of chat",
      },
      errorBoundary: {
        message: "Oops, we did not get to load the datas.",
        button: "Try Again",
      },
      reactions: {
        fromMe: "From Me",
        fromContact: "From Contact",
      },
      adsComponent: {
        identification: "Message from AD",
      },
      releasesModal: {
        title: "Releases Notes",
        upToDateSpan: "You are up to date! 🎉",
        buttons: {
          close: "Close",
        },
        patch: "Patch",
        labels: {
          implementations: "🔨 Implementations",
          adjustments: "🔃 Adjustments",
          bugFixes: "✅ Bug Fixes",
        },
        messages: {
          patch5: {
            implementations: {
              i1: "- forward messages (default connection)",
              i2: "- share contacts cards",
              i3: "- company name included into text greeting message",
            },
            adjustments: {
              a1: "- pending and in attendance chats filter to admins",
              a2: "- treating automatic translating from Google Chrome browser",
              a3: "- chats in attendance menu is now flexible in mobile version",
            },
            bugFixes: {
              b1: "- attach files by common users",
              b2: "- group interations by common users",
              b3: "- add new contacts",
            },
          },
          patch4: {
            implementations: {
              i1: "- option to import messages from WhatsApp",
              i2: "- identification of the end of each chat",
              i3: "- prioritized users to contacts",
              i4: "- greeting message being send even chip does not have any departments",
            },
            adjustments: {
              a1: "- new style to department, sector and user filters at active chats",
            },
            bugFixes: {
              b1: "- contacts transfer to users without access to departments",
            },
          },
          patch3: {
            implementations: {
              i1: "- screening graphic vissualization",
              i2: "- options to copy text message",
              i3: "- identification to voice and video calls received",
              i4: "- department edition in pending chats",
              i5: "- department filter on open and pending chats",
            },
            adjustments: {
              a1: "- step-by-step of how to connect a WhatsApp chip in the application",
              a2: "- label to identify whether a chat is retorned to pending",
              a3: "- dashboards with better performance",
            },
            bugFixes: {
              b1: "- broadcasts are not creating pending chats anymore",
            },
          },
          patch2: {
            implementations: {
              i1: "- you can now create chats for notes, star messages and express your feellings reacting to messages ❤️😮",
              i2: "- setting to send lists and buttons or text messages to contacts",
              i3: "- time zone selection",
              i4: "- new animations to action buttons",
              i5: "- added client code field on contacts",
              i6: "- awaiting maximum time to pending chats",
              i7: "- new filters in Chats Dashboard",
              i8: "- unlimited departments number",
            },
            adjustments: {
              a1: "- absence message is opcional for chips",
              a2: "- dashboards loading faster",
              a3: "- choose whether chat will be assigned to a user or it will be pending over opening chats",
            },
            bugFixes: {
              b1: "- awaiting time restarted while reopening chats",
              b2: "- Drag and Drop function is not crashing the application anymore",
              b3: "- labels with real-time info while opening chats to users",
            },
          },
          patch1: {
            implementations: {
              i1: "- conversations dashboard per user",
              i2: "- interactive conversation dashboard",
              i3: "- added response time tracking",
              i4: "- added option to close pending tickets without accepting them (for administrators only)",
              i5: "- menu now available on mobile",
              i6: "- contact profile picture display",
              i7: "- now you can send files to your contacts just dragging and dropping them",
            },
            adjustments: {
              a1: "- 'NaN' no longer appears in the general dashboard when the application is first used",
              a2: "- browsers now notify new messages",
              a3: "- queue and button selection and sending are working for WhatsApp Business as well",
              a4: "- when viewing a conversation that has already been closed, the page remains in the closed list",
              a5: "- messages from groups and private conversations have been separated",
            },
            bugFixes: {
              b1: "- image inclusion in the application",
              b2: "- changed page title colors",
              b3: "- changed secondary button colors",
              b4: "- all menu collapses start closed",
              b5: "- added file attachment and audio recording options for administrators when tickets are pending",
              b6: "- departments, categories, and sectors have been grouped in the menu",
              b7: "- added user list to conversation transfer option",
              b8: "- new scrollbar style",
            },
          },
        },
      },
      dragDropModal: {
        span: "Drag and drop your file here!",
        obs: "(just one file per attempt)",
        await: "Wait while we send your file!",
        confirmationModal: {
          title: "Would you like to send the file?",
          messages: {
            format: "Format:",
            size: "Size:",
            name: "Name:",
          },
        },
      },
      openAi: {
        expander: "BestZap Bot",
        inputPlaceholder: "Type your question here",
      },

      eventPreview: {
        videoCall: "WhatsApp video call",
        voiceCall: "WhatsApp voice call",
        seeDetails: "See Details",
      },

      pollPreview: {
        multipleAnswers: "Select one or more",
        singleAnswer: "Select one option",
        viewVotes: "View Votes",
      },
      locationPreview: {
        viewButton: "View",
      },
      listPreview: {
        select: "Select",
      },
      listPreviewModal: {
        title: "Select",
        toasts: {
          notSelectedOption: "Select an option before sending the message!",
        },
      },
      groupInvitePreview: {
        description: "Group Invite",
        joinButton: "Join",
        expiredButton: "Expired",
        confirmationModal: {
          title: "Would you to like to join the group:",
        },
        toasts: {
          invalidInviteCode: "Invalid invite code. Request another invite to the group's administrator!",
          inviteExpired: "Invite code expired. Request another invite to the group's administrator!",
        },
      },
      vCard: {
        confirmationModal: {
          title: "Would you like to talk to",
        },        
        button: 'Talk',
        info: "It is not possible to start a chat with this contact because its phone number has not been shared!",
        toasts: {
          noNumberProvided: 
            "It is not possible to open a chat with a shared contact without a valid WhatsApp Number. Ask to the sender send the contact again!",
        },
      },  
      multiVCard: {
        buttons: {
          showCards: "View all",
          talk: "Talk",
        },
        texts: {
          and: "and",
          otherContacts: "other contacts",
        },
      },
      image: {
        copyButton: "Copy",
        doodleButton: "Doodle",
        editButton: "Edit",
        resetButton: "Reset",
        undoButton: "Undo",
        cancelButton: "Cancel",
        sendButton: "Send",
        tooltipCopyButton: "Copied!",
        editingPanel: "Editing Image",
        success: "Image copied! Now you can paste it here if you want.",
      },
      badges: {
        closedContent: "Closed",
      },
      signup: {
        title: "Sign up",
        toasts: {
          fail: "Error creating user. Check the reported data.",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Register",
          login: "Already have an account? Log in!",
        },
      },
      login: {
        title: "BestZap",
        subtitle: "Login",
        subtitleGroup: "Type your email",
        subtitlePassword: "Type your password",
        form: {
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Enter",
          back: "Back",
          register: "Don't have an account? Register!",
        },
      },
      whatsappButtonComponent: {
        tooltip: "Talk to us!",
      },
      dashboard: {
        emptyBox: "No datas found in the selected periods",
        
        settings: {
          allowGroupsDisabled: "Groups are not enabled",
        },

        charts: {
          ticketsPerQueues: {
            title: "Chats per Departments",
          },
          ticketsPerSubqueues: {
            title: "Chats per Sectors",
          },
          ticketsPerConnection: {
            title: "Chats per Connections",
          },
          openTicketsPerUsers: {
            title: "Open Chats per Users",
          },
          closedTicketsPerUsers: {
            title: "Closed Chats per Users",
          },
          timePerUsers: {
            title: "Avg Time per Users (minutes)",
          },
          totalTimePerUsers: {
            title: "Total Time per Users (minutes)",
          },
        },
        fields: {
          initialDate: "Initial Date",
          finalDate: "Final Date",
          resaleSerialNumber: "Resale Serial Number",
          serialNumber: "Serial Number",
          clientCode: "Client Code",
          messageKeyword: "Keyword",
        },
        buttons: {
          update: "Update",
          filterMessageKeywords: "Filter by keywords",
        },
        messages: {
          inAttendance: {
            title: "In Service"
          },
          waiting: {
            title: "Waiting"
          },
          closed: {
            title: "Closed"
          },
          newContacts: {
            title: "New Contacts"
          },
          messages: {
            title: "Messages"
          },
          attendant: {
            title: "Attendants"
          },
          awaitingTime: {
            title: "Awaiting Time (Avg)"
          },
          attendanceTime: {
            title: "Attendance Time (Avg)"
          },
          time: {
            minute: "Minute",
            minutes: "Minutes",
            hour: "Hour",
            hours: "Hours",
            never: "Never",
          }
        }
      },
      generalDashboard: {
        title: "General Dashboard",
      },
      attendantsDashboard: {
        title: "Attendants Dashboard",
      },
      ratingsDashboard: {
        title: "Ratings Dashboard",
        cards: {
          rate: "Rate (Avg)",
          tickets: "Chats",
          contacts: "Contacts",
        },
        captions: {
          top5Users: "😀 Higher Rates",
          bottom5Users: "😥 Minor Rates",
          allUsers: "✨ All Users",
        },
        table: {
          id: "#",
          user: "User",
          rate: "Rate",
          ratingsCount: "Ratings",
        },
        tooltips: {
          tableView: "Table View",
          chartView: "Chart View",
        },
      },

      groupsDashboard: {
        title: "Groups Dashboard",

        cards: {
          chats: "Chats",
          newGroups: "New Groups",
        },
      },

      internalChatTicketsDashboard: {
        title: "Internal Chats Dashboard",
        amount: "Amount of Chats",
        faster: "Faster Chat",
        longer: "Longer Chat",
        minute: "minute",
        minutes: "minutes",
        hour: "hour",
        hours: "hours",

        table: {
          header: {
            id: "Chat",
            user: "User",
            chatName: "Chat Name",
            protocol: "Protocol",
            time: "Time",
            endedAt: "Ended At",
          },
          rows: {
            minute: "minute",
            minutes: "minutes",
            hour: "hour",
            hours: "hours",
          },
        },
      },

      ticketsDashboard: {
        title: "Chats Dashboard",
        amount: "Amount of Chats",
        faster: "Faster Chat",
        longer: "Longer Chat",
        minute: "minute",
        minutes: "minutes",
        hour: "hour",
        hours: "hours",

        table: {
          header: {
            id: "Chat",
            connection: "Connection",
            platform: "Platform",
            rate: "Rate",
            user: "User",
            contactName: "Contact Name",
            contactNumber: "Contact Number",
            resaleSerialNumber: "Resale Serial Number",
            serialNumber: "Serial Number",
            clientCode: "Client Code",
            queue: "Department",
            category: "Category",
            subqueue: "Sector",
            protocol: "Protocol",
            time: "Time",
            endedAt: "Ended At",
          },
          rows: {
            minute: "minute",
            minutes: "minutes",
            hour: "hour",
            hours: "hours",
          },
        },

        charts: {
          title: "Chats Distribution: User",
        },
      },
      connections: {
        title: "Connections",
        usedChipsCount: {
          outOf: "out of",
          chips: "chip(s)",
        },
        card: {
          defaultConnection: "Default Connection",
          notDefaultConnection: "Non-Default Connection",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "Are you sure? It cannot be reverted.",
          disconnectTitle: "Disconnect",
          disconnectMessage: "Are you sure? You'll need to read QR Code again.",
        },
        buttons: {
          add: "Add WhatsApp",
          disconnect: "Disconnect",
          delete: "Delete",
          info: "Info",
          tryAgain: "Try Again",
          qrcode: "QR CODE",
          newQr: "New QR CODE",
          connecting: "Connectiing",
        },
        toolTips: {
          copyApiToken: "Copy API Token",
          copied: "Copied!",
          disconnected: {
            title: "Failed to start WhatsApp session",
            content:
              "Make sure your cell phone is connected to the internet and try again, or request a new QR Code",
          },
          qrcode: {
            title: "Waiting for QR Code read",
            content:
              "Click on 'QR CODE' button and read the QR Code with your cell phone to start session",
          },
          connected: {
            title: "Connection established",
          },
          timeout: {
            title: "Connection with cell phone has been lost",
            content:
              "Make sure your cell phone is connected to the internet and WhatsApp is open, or click on 'Disconnect' button to get a new QRcode",
          },

          importingMessages: {
            title: "Messages are being imported",
          },

          importedMessages: {
            title: "All messages have been imported",
          },
        },
        table: {
          name: "Name",
          status: "Status",
          lastUpdate: "Last Update",
          default: "Default",
          actions: "Actions",
          session: "Session",
        },
      },
      whatsappInfoModal: {
        title: "Connection info",
        datas: {
          platform: "Platform:",
          whatsAppUserName: "WhatsApp user name:",
          phoneNumber: "Phone number:",
        },
      },
      whatsappModal: {
        title: {
          add: "Add WhatsApp",
          edit: "Edit WhatsApp",
        },
        form: {
          settings: "Settings",
          name: "Name",
          default: "Default",
          dispatch: "Dispatch",
          sendRating: "Rating poll",
          shouldUseChipNameOnAutomaticMessages: "Use connection's name on automatic messages title",
          closeNewTicketsWithMessageOutsideApplication: "Close chats started by messages outside application",
          waitForBotInteraction: "Wait for bot interaction",
          sendSignature: "Send signature on messages",
          greetingMessage: "Greeting Message",
          awaitingMessage: "Awaiting Message",
          farewellMessage: "Farewell Message",
          absenceMessage: "Absence Message",
          declinedCallMessage: "Declined Call Message",
          alertContactPhoneNumber: "Alert Phone Number",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        info: {
          message: "To add Dinamic Datas, tip them between brackets: (contactname)",
          variablesText: "Available variables:",
          variablesList: "(contactname), (greeting)",

          alertContactPhoneNumberMessage: "The phone number is only notified about out of business pending chats",
        },
        tooltips: {
          waitForBotInteraction: "Pending tickets are only shown for users after contact interacted with bot. Admins can see all pending tickets.",
          invalidAlertContactPhoneNumber: "Invalid alert phone number!",
          alertContactPhoneNumberNotOnWhatsapp: "Alert Phone Number is not registered on WhatsApp!",
        },
      },
      qrCode: {
        message: "Read QR Code to start the session",
        steps: {
          message1: "- Open WhatsApp on you phone",
          message2: "- Tap 'Menu' or 'Settings' and select 'Linked Devices'",
          message3: "- Tap on 'Link a Device'",
          message4: "- Point your phone to this screen to capture the QR Code",
          message5: "- Consider changing to Light Theme to better capture the QR Code",
        },
        loadingScreenStatus: {
          loadingChats: "Loading your chats",
        },
      },
      contacts: {
        title: "Contacts",
        tooltips: {
          listLayout: "List layout",
          cardLayout: "Card layout",
          contactsLayout: "Contacts",
          groupsLayout: "Groups",
          channelsLayout: "Channels",
          blockedLayout: "Blocked",
          inactivatedLayout: "Inactivated Contacts",
        },
        messages: {
          outOf: "out of",
          contacts: "contacts",
        },
        toasts: {
          groupsNotEnabled: "Groups are not enabled! You can enable them on Settings Page.",
          channelsNotEnabled: "Channels are not enabled! You can enable them on Settings Page.",
        },
        searchPlaceholder: "Search ...",
        confirmationModal: {
          blockTitle: "Block contact",
          unblockTitle: "Unblock contact",
          blockMessage: "Are you sure on blocking the contact?",
          unblockMessage: "Are you sure on unblocking the contact?",

          activeTitle: "Active",
          inactiveTitle: "Inactive",
          activeMessage: "Are you sure you want to active this contact?",
          inactiveMessage: "Are you sure you want to inactive this contact? You will not be able to chat with it anymore.",

          importContacts: "Import Contacts",
          importGroups: "Import Groups",
          importChannels: "Import Channels",

          deleteTitle: "Delete",
          importTitlte: "Import contacts",
          importMessagesTitle: "Import Messages",

          deleteMessage: "Are you sure you want to delete this contact? All related chats will be lost.",
          deleteGroupMessage: "Are you sure you want to delete this group? Its chat will be lost.",
          deleteChannelMessage: "Are you sure you want to delete this channel? Its chat will be lost.",
          importMessage: "Do you want to import all contacts from the phone?",
          importMessagesMessage: "Are you sure you want to import all messages from the phone? It will import the last 1000 messages from the last three months for each chat in your phone.",

          form: { chip: "Chip" },

          openPrivateChat: { title: "Would you like to open a chat with" },
          reopenPrivateChat: { title: "Would you like to reopen the chat?" },

          toast: {
            importingMessages: "The messages are being imported, you can keep working while we do the job for you!",
            noConnectedChip: "There are no connected chips!",
          },
        },
        accordion: {
          title: "Labels",
          title2: "Default Connection",
          title3: "Medias",
          noLabels: "No labels assigned to the Contact",
          showAllMedias: "Show all",
        },
        buttons: {
          importMessages: "Import Messages",
          import: "Import Contacts",
          add: "Add Contact",
          exportCSV: "Export contacts by CSV File",
          importCSV: "Import contacts by CSV File",
        },
        table: {
          name: "Name",
          label: "Label",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Actions",
        },
      },
      viewTicketModal: {
        title: "Chat",

        buttons: {
          return: "Return",
          exportToPDF: "Export to PDF",
          openPrivateChat: "Chat",
        },
      },
      exportCSVModal: {
        title: "Export contacts to CSV File",
        form: {
          contactType: {
            label: "Contact Type",
            nonBlockedOption: "Non-Blocked",
            blockedOption: "Blocked",
            inactivatedOption: "Inactivated",
            bothOption: "All",
          },
          separateContacts: {
            label: "Separate Non-blocked contacts to the Blocked and Inactivated ones",
          },
        },
        buttons: { cancel: "Cancel", export: "Export", },
        toasts: {
          error: "It was not possible to export the contacts. Try again later!",
          noContactsToExport: "There are any contacts to export!",
        }
      },
      importCSVModal: {
        title: "Import contacts by CSV File",
        importSpan: "Click here to upload your File",
        fileName: "File to import:",
        info1: "The CSV file must have four columns named ContactName, ContactNumber, ContactEmail and ContactConnectionName.",
        info2: "Also must contain header and all values must be strings!",
        warning: "Some file lines does not have the required variables filled (Contact Name and Number), so they have been discarded!",
        buttons: {
          cancel: "Cancel",
          import: "Import",
        },
        toasts: {
          notSupportedFormat: "Only CSV files are supported!",
          notUploadedFile: "There is any CSV file!",
          moreVariablesThanLimit: "The file has more variables than expected! Try with other file.",
          lessVariablesThanLimit: "The file has less variables than expected! Try with other file.",
          headerNotExpected: "The file does not contain the required columns.",
          noDefaultWhatsappConnected: "You don't have a default connection or it's not connected. Check out the Connections page!",
          contactsImported: "All contacts have been imported! Reload the page to see your new contacts!",
        },
        importationWords: {
          imported: "Imported",
          of: "of",
          contacts: "contacts",
        },
        tooltips: {
          stopImportation: "Stop importation",
        },
      },
      contactModal: {
        title: {
          add: "Add contact",
          edit: "Edit contact",
        },
        toasts: {
          invalidCPF: "Invalid CPF!",
          nonexistentCPF: "Nonexistent CPF!",
          invalidCNPJ: "Invalid CNPJ!",
          nonexistentCNPJ: "Nonexistent CNPJ!",
          invalidBirthday: "Birthday cannot be after today!",
        },
        form: {
          mainInfo: "Contact details",
          extraInfo: "Additional information",
          whatsapp: "Default Connection",
          name: "Name",
          label: "Label",
          number: "Whatsapp number",
          cpfCnpj: "CPF/CNPJ",
          birthday: "Birthday",
          surname: "Surname",
          serialNumber: "Serial Number",
          resaleSerialNumber: "Resale Serial Number",
          clientCode: "Client Code",
          email: "Email",
          extraName: "Field name",
          extraValue: "Value",
          isBot: "Do not send Automatic Messages",
          sendButtonsLists: "Do not send buttons and lists",
          fieldLabel: "Prioritize user",
        },
        buttons: {
          addExtraInfo: "Add information",
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
          importMessages: "Import messages",
        },
        importingMessagesBaileys: "The messages are being imported. You can come back to work while we do the job for you!",
        confirmationModal: {
          titleBaileys: "Would you like to import the messages?",
          messageBaileys: "This action can only be done once per contact. Also, some image, video, audio and file messages may not be imported!",
        },
      },
      quickAnswersModal: {
        title: {
          add: "Add Quick Reply",
          edit: "Edit Quick Answer",
        },
        form: {
          shortcut: "Shortcut",
          message: "Quick Reply",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
      },
      categoryModal: {
        title: {
          add: "Add category",
          edit: "Edit category",
        },
        form: {
          name: "Name",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
      },
      subqueueModal: {
        title: {
          add: "Add sector",
          edit: "Edit sector",
        },
        form: {
          name: "Name",
          description: "Description",
          category: "Category",
          queue: "Department",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
      },
      queueModal: {
        title: {
          add: "Add department",
          edit: "Edit department",
        },
        form: {
          isDefault: "Default",
          name: "Name",
          color: "Color",
          greetingMessage: "Greeting Message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
      },
      viewOnceFileContextModal: {
        title: {
          up: "Send View Once File",
        },
      },
      fileContextModal: {
        title: {
          up: "Send File",
        },
        tooltips: {
          cancel: "Cancel",
          confirm: "Confirm",
        },
        textfield: "Legend",
        noVisualizationMessage: "Preview not available!",
        addFile: "Add file",
      },
      editMessageModal: {
        title: {
          up: "Edit Message",
        },
        textfield: "New message",
        toasts: {
          blankInput: "It is not possible to edit a message to be blank!",
        },
      },
      editMessageHistoryModal: {
        title: {
          up: "Message Edition History",
        },
      },

      messagePropertiesModal: {
        title: { up: "Message Properties", },

        spans: {
          internalMessage: "Internal Message",
          date: "Date",
          hour: "Hour",
          sentBy: {
            sentBy: "Sent By",
            me: "Me",
            contact: "Contact",
          },
          user: "User",
          connection: "Connection",
          connectionNumber: "Connection Number",
        },

        buttons: { cancel: "Cancel", },

        tooltips: { copy: "Copy", copied: "Copied", },
      },

      forwardMessageContactModal: {
        title: {
          up: "Contact to Forward Message",
        },
        tooltips: {
          send: "Forward",
        },
        contactType: {
          group: "Group",
        },
        confirmationModal: {
          title: "Would you like to forward the messages to contact?",
          name: "Name:",
          number: "Number:",
        },
      },

      eventDetailsModal: {
        title: {
          up: "Event Details",
        },

        buttons: {
          startDateTime: "Add to calendar",
          location: "View on maps",
          call: "Copy link",
        },

        tooltips: {
          copied: "Copied",
        },
      },

      pollVotesModal: {
        title: {
          up: "Poll Votes",
        },
        votes: "Votes",
      },
      multiVCardModal: {
        title: {
          up: "View contacts",
        },
        confirmationModal: {
          title: "Would you like to talk to",
        },
        toasts: {
          noNumberProvided: 
            "It is not possible to open a chat with a shared contact without a valid WhatsApp Number. Ask to the sender send the contact again!",
        },
      },
      imageToStickerModal: {
        title: {
          up: "Create Sticker",
        },
        buttons: {
          cancel: "Cancel",
          send: "Send",
        },
      },
      editImageModal: {
        title: {
          up: "Edit Image",
        },
        buttons: {
          cancel: "Cancel",
          copy: "Copy",
          send: "Send",
          confirm: "Confirm",
        },
      },
      mediasGalleryModal: {
        title: {
          up: "Medias gallery",
        },
        tabBar: {
          images: "Images",
          videos: "Videos",
          audios: "Audios",
          others: "Others",
        },
      },
      stickersModal: {
        title: {
          up: "Stickers",
        },
        tabBar: {
          dracula: "Dracula",
          penguin: "Penguin",
          pumpkin: "Pumpkin",
          bunny: "Bunny",
          bot: "Bot",
          elf: "elf",
        },
        toasts: {
          sendSticker: "It was not possible to send the sticker, try again later!",
        },
      },    
      groupParticipantsModal: {
        title: {
          up: "Participants",
        },
      },
      newContactsModal: {
        title: {
          up: "New contacts",
        },
        contactType: {
          group: "Group",
        },
      },
      shareContactModal: {
        title: {
          up: "Share contact",
        },
        buttons: {
          send: "Share",
        },
        confirmationModal: {
          title: "Would you like to share the contact?",
        },
        toast: {
          limit: "You can send 5 contacts at a time!",
        }
      },
      cameraModal: {
        title: "Take photo",
        buttons: {
          cancel: "Cancel",
        },
        messages: {
          noVideoInputs: "Your device does not contain any video inputs.",
        },
        toasts: {
          exceptionListingDevices: "It was not possible to list the available cameras. Check out if the camera permission is allowed. If that's so, try again later!",
        },
        tooltips: {
          turnOnCamera: "Turn on camera",
          turnOffCamera: "Turn off camera",
          snapCamera: "Take photo",
          copyPhoto: "Copy photo",
          sendPhoto: "Send photo",
        },
      },
      pollModal: {
        title: "Poll",
        fields: {
          allowMultipleAnswers: "Allow multiple answers?",
          titleLabel: "Title",
          optionA: "Option A",
          optionB: "Option B",
          newOptionLabel: "New option",
        },
        schemaValidations: {
          tooShort: "Too short!",
          tooLong: "Too long!",
          required: "Required",
        },
        buttons: {
          send: "Send",
          cancel: "Cancel",
          newExtraOption: "New option",
        },
      },
      locationModal: {
        title: "Send location",
        buttons: {
          currentLocation: "Current",
          companyLocation: "Company",
          cancel: "Cancel",
        },
      },
      userModal: {
        title: {
          add: "Add user",
          edit: "Edit user",
        },

        form: {
          name: "Name",
          email: "Email",
          password: "Password",
          whatsappNumber: "WhatsApp Number",
          profile: "Profile",
          whatsapp: "Default Connection",
          admin: "Admin",
          user: "User",
          configEnabled: "Allow access Settings",
          forceAcceptLongAwaitingTimeTickets: "Force accept long awaiting time tickets",
        },

        tooltips: {
          invalidWhatsappNumber: "Invalid phone number!",
          whatsappNumberNotOnWhatsapp: "Phone Number is not registered on WhatsApp!",
        },

        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },

        defaultConnectionMustBeSelected: "Default connection must be selected on 'Connections' field too!",
        emailAlreadyRegistered: "Email already registered!",
      },
      chat: {
        noTicketMessage: "Select a chat to start chatting.",
      },
      ticketsManager: {
        fields: {
          name: "Name",
        },

        buttons: {
          newTicket: "New Chat",
          newNote: "New Note",
          newInternalChat: "New Internal Chat",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Departments",
      },
      ticketInfo: {
        composing: "Typing...",
        recording: "Recording...",
        lastSeenToday: "Last seen today at",
        lastSeenAt: "Last seen at",
      },
      tickets: {
        toasts: {
          add: "Chat saved successfully!",
          deleted: "The chat you were on has been deleted.",
        },
        notification: {
          message: "Message from",
          internalChat: "Internal Chat",
          expirationWarning: "Autoshutdown in @ minute(s)",
          hasMessageFromPhone: "Messaging from WhatsApp App",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolved" },
        },
        buttons: {
          showAll: "Show All Chats",
          showAllOff: "Hide All Chats",
        },
      },
      editTicketModal: {
        title: "Edit Chat",
        
        fieldLabelLabel: "Label",
        fieldLabelPlaceholder: "Label",
        labelInfo: "You do not have any labels. Please, create some labels before closing this chat!",


        fieldQueueLabel: "Department",
        fieldQueuePlaceholder: "Please select a department",
        fieldSubqueueLabel: "Sector",
        fieldSubqueuePlaceholder: "Please select a sector",
        
        buttons: {
          ok: "Edit",
          cancel: "Cancel",
        },
        toast: {
          subqueuesNotLoaded: "Checking the choosen queue. Try again in a few seconds!",
        },
      },

      searchMessageModal: {
        title: "Search Message",
        searchPlaceholder: "Keywords",
        
        toasts: {
          smallLengthSearchParam: "Small text for searching, add more information to improve the search!",
        },

        confirmationModal: {
          title: "Would you like to go to the selected message history?",
        },
      },

      transferTicketModal: {
        title: "Transfer Chat",
        fieldLabel: "Select a user",
        fieldQueueLabel: "Transfer to department",
        fieldConnectionLabel: "Transfer to connection",
        fieldQueuePlaceholder: "Please select a department",
        fieldConnectionPlaceholder: "Please select a connection",
        fieldText: "Transference observation",
        noOptions: "No user found with this name",
        buttons: {
          ok: "Transfer",
          cancel: "Cancel",
        },
        toast: {
          userNotSelected: "You should select a user before transferring a chat!",
        },
        listSubheaders: {
          onlineUsers: "Online Users",
          offlineUsers: "Offline Users",
        },
      },
      ticketsList: {
        timeAwaitDay: "day",
        timeAwaitHour: "hour",
        timeAwaitMinute: "minute",
        timeAwaitSecond: "second",
        timeAwaitNow: "Now",
        pendingHeader: "Pending",
        groupHeader: "Groups",
        channelHeader: "Channels",
        assignedHeader: "Working on",
        noTicketsTitle: "Nothing here!",
        noTicketsMessage: "No chats found with this status or search term.",
        connectionTitle: "Connection that is currently being used.",
        isReturned: "Returned Chat",
        isTransfered: "Transfered Chat",
        buttons: {
          accept: "Accept",
        },
      },
      newTicketModal: {
        title: "Create Chat",
        fieldLabel: "Type to search for a contact",
        connection: "Connection",
        add: "Add",
        buttons: {
          ok: "Save",
          cancel: "Cancel",
          toogle: "Assign chat to me?",
        },
        toasts: {
          whatsappNotSelected: "A connection must be selected!",
        },
      },
      newNoteModal: {
        title: "Create Note",
        form: {
          name: "Name",
          extraInfo: "Aditional Information",
          extraName: "Field Name",
          extraValue: "Value",
        },
        buttons: {
          ok: "Save",
          cancel: "Cancel",
          toogle: "Assign note to me?",
          addExtraInfo: "Add Information",
        },
      },
      newInternalChatModal: {
        title: "Create Internal Chat",

        form: {
          name: "Name",
          user: "User",
        },

        listSubheaders: {
          onlineUsers: "Online Users",
          offlineUsers: "Offline Users",
        },

        buttons: {
          ok: "Save",
          cancel: "Cancel",
        },

        toasts: {
          chatNameInfo: "You must assign a name with at least 3 characters to the chat!",
          selectedUserIdInfo: "You must select a user to chat with!",
        },
      },
      newOpenAiModal: {
        title: "Create Bot Chat",
        form: {
          name: "Name",
        },
        buttons: {
          ok: "Save",
          cancel: "Cancel",
          toogle: "Assign bot chat to me?",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          schedule: "Schedule",
          general: "General",
          attendants: "Attendants",
          connections: "Connections",
          tickets: "Chats",
          groupsDashboard: "Groups",
          ratings: "Ratings",
          internalChats: "Internal Chats",
          ticketsOpen: "Actives",
          ticketsClosed: "Closed Today",
          ticketsSearch: "Search",
          contacts: "Contacts",
          labels: "Labels",
          messages: "Messages",
          automaticMessages: "Automatic Messages",
          quickAnswers: "Quick Answers",
          starredMessages: "Starred",
          businessHours: "Business Hours",
          screening: "Screening",
          queues: "Departments",
          categories: "Categories",
          subqueues: "Sectors",
          flow: "Flow",
          administration: "Administration",
          users: "Users",
          profile: "Company",
          dispatch: "Dispatch",
          managerDispatch: "Manager",
          feedbackDispatch: "Feedback",
          settings: "Settings",
          releases: "Releases",
        },
        appBar: {
          sound: "Alerts",
          language: "Language",
          theme: "Theme",
          user: {
            profile: "Profile",
            logout: "Logout",
          },
        },
      },
      notifications: {
        noTickets: "No notifications.",
        notificationsNotSupported: "Notifications are not supported in this browser!",
      },
      businessHours: {
        title: {
          title1: "Business Hours",
        },
        buttons: {
          save: "Save",
        },
        assets: {
          to: "to",
          personalizedMessage: "Personalized Message",
          businessHour: "Business Hour",
          intervalHour: "Break",
        },
        checkbox: {
          open: "Open",
          closed: "Closed",
        },
        weekdays: {
          sunday: "Sunday",
          monday: "Monday",
          tuesday: "Tuesday",
          wednesday: "Wednesday",
          thursday: "Thursday",
          friday: "Friday",
          saturday: "Saturday",
        },
      },
      categories: {
        title: "Categories",
        table: {
          name: "Name",
          actions: "Actions",
        },
        buttons: {
          add: "Add category",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? It cannot be reverted! Chats in this category will still exist, but will not have any categories assigned.",
        },
      },
      subqueues: {
        title: "Sectors",
        table: {
          name: "Name",
          description: "Description",
          actions: "Actions",
          category: "Category",
          queue: "Department",
        },
        buttons: {
          add: "Add sectors",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? It cannot be reverted! Chats in this sector will still exist, but will not have any sectors assigned.",
        },
      },
      schedule: {
        title: "Schedule",
        buttons: {
          add: "Add Event",
        },
        messages: {
          months: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
          },
          monthsShort: {
            jan: 'Jan',
            feb: 'Feb',
            mar: 'Mar',
            apr: 'Apr',
            may: 'May',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Aug',
            sep: 'Sep',
            oct: 'Oct',
            nov: 'Nov',
            dec: 'Dec'
          },
          weekdays: {
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
          },
          weekdaysShort: {
            sun: 'Sun',
            mon: 'Mon',
            tue: 'Tue',
            wed: 'Wed',
            thu: 'Thu',
            fri: 'Fri',
            sat: 'Sat',
          },
          buttons: {
            month: 'Month',
            week: 'Week',
            day: 'Day',
            agenda: 'Agenda',
            work_week: 'Work Week',

            today: 'Today',
            next: 'Next',
            previous: 'Previous',

            date: 'Date',
            time: 'Time',
            event: 'Event',
            events: 'Events',

            noEventsInRange: 'There are no events in the range',
            allDay: 'All Day',
          },
        },
      },
      flow: {
        title: "Flow",
        counts: {
          queues: "Departments",
          categories: "Categories",
          subqueues: "Sectors",
        },
        tree: {
          chip: "Chip",
          queue: "Department",
          category: "Category",
          subqueue: "Sector",
        },
        messages: {
          noWhatsapp: "You have not added any connections yet!",
          noFlow: "You have not added any departments, categories and sectors yet!",
        },
        speedDial: {
          newDepartment: "New department",
          newCategory: "New category",
          newSector: "New sector",
        },
      },
      queues: {
        title: "Departments",
        table: {
          isDefault: "Default",
          name: "Name",
          color: "Color",
          greeting: "Greeting message",
          actions: "Actions",
        },
        buttons: {
          add: "Add department",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? It cannot be reverted! Chats in this department will still exist, but will not have any departments assigned.",
        },
      },
      queueSelect: {
        inputLabel: "Departments",
      },
      whatsappSelect: {
        inputLabel: "Connections",
      },
      userSelect: {
        inputLabel: "Users",
      },
      contactSelect: {
        inputLabel: "Contacts",
        searchTextPlaceholder: "Search...",
      },
      categorySelect: {
        inputLabel: "Categories",
      },
      subqueueSelect: {
        inputLabel: "Sectors",
      },
      connectionSelect: {
        inputLabel: "Connection",
      },
      labelSelect: {
        inputLabel: "Label",
      },
      ticketTypeSelect: {
        inputLabel: "Platform",
      },
      starredMessages: {
        title: "Starred",
        loadMoreButton: "Load More",
        table: {
          actions: "Actions",
          message: "Message",
          user: "User",
        },
        confirmationModal: {
          title: "Would you like to unstar the message?",
        },
      },

      automaticMessages: {
        title: "Automatic Messages",
        searchPlaceholder: "Search...",

        confirmationModal: {
          deleteTitle: "Delete Automatic Message?",
        },

        buttons: {
          add: "Add Automatic Message",
        },

        table: {
          name: "Name",
          keywords: "Keywords",
          body: "Message",
          actions: "Actions",
        },
      },

      automaticMessagesModal: {
        title: {
          add: "Add automatic message",
          edit: "Edit automatic message",
        },

        form: {
          name: "Name",
          body: "Message",

          buttons: {
            keywords: "Keywords",
            medias: "Medias",
          },
        },

        validations: {
          name: "A name with 3 to 25 characters must be provided!",
          body: "A message with 7 to 500 characters must be provided!",
          whatsappIds: "At least one connection must be selected!",

          nameUnicity: "The name is already being used by another Automatic Message. Consider using another one!",
          keywordsUnicity: "Some keywords are already being used in another Automatic Message. Consider removing or replacing them by another one!",
        },

        buttons: {
          okAdd: "Add",
          okEdit: "Edit",
          cancel: "Cancel",
        },
      },

      automaticMessagesKeywordsModal: {
        title: "Keywords",

        duplicatedKeywords: "Duplicated Keywords:",

        form: {
          mainKeyword: "Main",
          alternativeKeyword: "Alternative",
        },

        validations: {
          mainKeyword: "A main keyword must be informed!",
        },

        buttons: {
          addAlternativeKeyword: "Add Keyword",
          confirm: "Confirm",
        },
      },

      automaticMessagesMediasModal: {
        title: {
          up: "Medias",
        },

        noVisualizationMessage: "Preview not available!",
        addFile: "Add File",

        buttons: {
          cancel: "Cancel",
          confirm: "Confirm",
        },
      },

      quickAnswers: {
        title: "Quick Answers",
        table: {
          shortcut: "Shortcut",
          message: "Quick Reply",
          actions: "Actions",
        },
        buttons: {
          add: "Add Quick Reply",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Quick Reply: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      versionModal: {
        title: {
          up: "Current Version",
        },
        tooltips: {
          copy: "Copy",
          copied: "Copied",
        },
        spans: {
          year: "Year",
          month: "Month",
          day: "Day",
          hour: "Hour",
          minute: "Minute",
          group: "Group",
          tenant: "Tenant",
        },
      },
      labelModal: {
        title: {
          edit: "Edit label",
          add: "Add label",
        },
        form: {
          name: "Name",
          color: "Color",
          description: "Description",
        },
        buttons: {
          cancel: "Cancel",
          okEdit: "Save",
          okAdd: "Add",
        },
      },
      labels: {
        title: "Labels",
        searchPlaceholder: "Search...",
        buttons: {
          add: "Add Label",
        },
        table: {
          name: "Name",
          color: "Color",
          description: "Description",
          actions: "Actions",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "Are you sure? It cannot be reverted! Contacts in this label will still exist, but will not have any label assigned.",
        },
      },

      dispatchManager: {
        title: "Dispatch",
        searchPlaceholder: "Search ...",

        buttons: {
          add: "Add Dispatch",
        },

        validation: {
          dispatchAlreadySent: "It was not possible to conclude the action because there is at least one dispatch already sent!",
        },

        table: {
          name: "Name",
          startDatetime: "Start",
          recurrence: "Recurrence",
          status: "Status",
          connection: "Connection",
          actions: "Actions",

          recurrenceValues: {
            once: "Once",
            weekly: "Weekly",
            monthly: "Monthly",
          },

          statusValues: {
            cancelled: "Cancelled",
            concluded: "Concluded",
            inProgress: "In Progress",
            pending: "Pending",
          },
        },

        confirmationModals: {
          cancelTitle: "Cancel Dispatch?",
          deleteTitle: "Delete Dispatch?",
          uncancelTitle: "Uncancel Dispatch?",
        },
      },

      buyDispatch: {
        message: "Dispatch is not enabled for your account. Tap on the button to know more about the functionality and contract it!",

        buttons: {
          buy: "Contract",
        },
      },

      dispatchManagerModal: {
        title: {
          add: "Add dispatch",
          edit: "Edit dispatch",
        },

        buttons: {
          cancel: "Cancel",
          okAdd: "Add",
          okEdit: "Save",
        },

        form: {
          name: "Name",
          datetimeToDispatch: "Start",
          recurrence: "Recurrence",
          connection: "Connection",

          recurrenceValues: {
            once: "Once",
            weekly:"Weekly",
            monthly: "Monthly",
          },

          buttons: {
            contacts: "Contacts",
            messages: "Messages",
          },
        },

        validations: {
          nameSize: "Dispatch name must contain between 3 and 50 characters!",
          datetimeToDispatchEmpty: "Select a start datetime to dispatch messages!",
          datetimeToDispatchInvalid: "The start datetime to dispatch messages must be at least one hour later than now!",
          recurrenceInvalid: "Select a recurrence to dispatch messages!",
          connectionInvalid: "Select a connection to dispatch messages!",

          message1Size: "Message 1 must be filled and contains between 3 and 500 characters!",
          otherMessagesSize: "Filled messages must contain between 3 and 500 characters!",

          contactsSize: "At least 3 contacts must be selected!",
        },
      },

      dispatchSendingsModal: {
        title: "Dispatch Sendings",
        dispatch: "Dispatch:",

        confirmationModals: {
          cancelTitle: "Cancel Dispatch Sending?",
          uncancelTitle: "Uncancel Dispatch Sending?",
        },

        validation: {
          dispatchAlreadySent: "It was not possible to conclude the action because the dispatch was already sent!",
        },

        filters: {
          nameNumber: "Name or Number",
        },

        table: {
          contactName: "Contact",
          contactNumber: "Number",
          message: "Message",
          status: "Status",
          errorDescription: "Error",
          lastUpdateAt: "Last Update",
          actions: "Actions",

          statusValues: {
            cancelled: "Cancelled",
            concluded: "Concluded",
            error: "Error",
            inProgress: "In Progress",
            pending: "Pending",
          },
        },

        buttons: {
          close: "Close",
        },
      },

      dispatchSendingMessageModal: {
        title: "Message",
      },

      dispatchSendingErrorDescriptionModal: {
        title: "Error Description",
      },

      ratingsSelect: {
        inputRating: "Ratings",
      },

      dispatchStatusSelect: {
        inputStatus: "Status",

        statusValues: {
          cancelled: "Cancelled",
          concluded: "Concluded",
          error: "Error",
          inProgress: "In Progress",
          pending: "Pending",
        },
      },

      dispatchManagerMessagesModal: {
        title: "Messages",

        buttons: {
          cancel: "Cancel",
          confirm: "Confirm",
        },

        form: {
          message1: "Message 1",
          message2: "Message 2",
          message3: "Message 3",
        },
      },

      dispatchManagerContactsModal: {
        title: "Contacts",

        filters: {
          nameNumber: "Name or Number",
          birthday: "Birthday",
          label: "Label",
        },

        buttons: {
          additionalContacts: "Additional Contacts",
          cancel: "Cancel",
          confirm: "Confirm",
        },
      },

      dispatchManagerAdditionalContactsModal: {
        title: "Additional Contacts",

        invalidContacts: "Invalid Contacts",

        form: {
          additionalContacts: "Additional Contacts",
        },

        validations: {
          invalidAdditionalContacts: "There are some typed invalid phone numbers!",
        },

        buttons: {
          cancel: "Cancel",
          validate: "Validate",
        },
      },

      transferList: {
        choices: "Choices",
        chosen: "Chosen",
        selected: "selected",
      },

      users: {
        title: "Users",
        searchPlaceholder: "Search ...",
        usedUsersCount: {
          outOf: "out of",
          users: "users(s)",
        },
        tooltips: {
          listLayout: "List layout",
          cardLayout: "Card layout",
          bothLayout: "All",
          adminsLayout: "Admins",
          usersLayout: "Users",
          admin: "Administrator",
        },
        table: {
          name: "Name",
          email: "Email",
          profile: "Profile",
          whatsapp: "Default Connection",
          actions: "Actions",
        },
        buttons: {
          add: "Add user",
        },
        toasts: {
          notDeleted: "You cannot delete your own user!",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "All user data will be lost. Users' open chats will be moved to queue.",
        },
      },
      settings: {
        success: "Settings saved successfully.",
        title: "Settings",
        settings: {
          general: "General",
          privacy: "Privacy",
          schedule: "Schedule",
          sendings: "Sendings",
          service: "Service",
          options: {
            enabled: "Enabled",
            disabled: "Disabled",
            
            lessRecent: "Less Recent",
            moreRecent: "More Recent",
            
            never: "Never",

            chats: "Chats",
            groups: "Groups",
            open: "Open",
            pending: "Pending",
            both: "Both",
          },
          ticketsListSortMethod: {
            name: "Chat lists sorting",
          },
          ticketsDynamicSorting: {
            name: "Dynamic chat sorting",
            tooltip: "The chats are sorted by the most recent message",
          },
          enterIsSendMobile: {
            name: "Enter is send (only for mobiles)",
          },
          fetchUnreadMessagesAfterConnecting: {
            name: "Fetch unread messages after chip connection",
          },
          userPermissionsByChip: {
            name: "Users permissions by connection",
          },
          allowDefaultQueue: {
            name: "Allow default department",
          },
          allowGroups: {
            name: "Allow groups",
          },
          allowChannels: {
            name: "Allow channels",
          },
          timezone: {
            name: "UTC offset",
          },
          missedCalls: {
            name: "Create chats to missed calls",
          },
          linkPreview: {
            name: "Send link preview",
          },
          notificationSoundForInternalChats: {
            name: "Notification sound for internal chats",
          },
          mergeMessagesFromDifferentConnectionsSetting: {
            name: "See messages from all connections",
          },
          showOnlyMessagesRelatedToUsersChats: {
            name: "Show only messages related to users' chats",
            tooltip: "Only works for non-administrator users",
          },
          showMessagesRelatedToUserQueues: {
            name: "Show messages related to users' departments",
            tooltip: "It only works if 'Show only messages related to users' chats' setting is disabled",
          },
          viewPresenceUpdate: {
            name: "Show presence update on chats",
            tooltip: "Option must also be enabled in your WhatsApp phone device. Also, presences does not work on groups and channels",
          },
          markMessagesAsRead: {
            name: "Mark messages as read on app",
          },
          requireTagOnContacts: {
            name: "Require label on contacts",
          },
          sendSignatureOnMessages: {
            name: "Send signature on messages",
          },
          adminCanViewOtherChats: {
            name: "Open the conversation if it is already active with another user",
          },
          automaticallyRejectCalls: {
            name: "Automatically reject calls",
            tooltip: "When enabled, a message is automatically sent to the contact",
          },
          apiToken: {
            name: "API Token",
            copyTooltip: "Copy",
            copiedTooltip: "Copied",
          },
          prioritizeUsers: {
            name: "Prioritize users to chat with contact",
          },
          prioritizeUsersTime: {
            name: "Priority users time (minutes)",
          },
          allowTransferTicketsToOfflineUsers: {
            name: "Allow transfer chats to offline users",
          },
          allowResolveTicketWithoutGoodBye: {
            name: "Allow resolve chats without sending farewell message",
          },
          adminsPendingTicketsControl: {
            name: "Admins control pending chats",
          },
          chatExpiration: {
            name: "Chat expiration time - Pending",
          },
          chatExpirationOpened: {
            name: "Chat expiration time - Open",
          },
          sendRatingPollOnChatExpiration: {
            name: "Send rating poll to chat expiration - Pending",
          },
          sendRatingPollOnChatExpirationOpened: {
            name: "Send rating poll to chat expiration - Open",
          },
          sendListsButtons: {
            name: "Send lists and buttons",
          },
          awaitingTime: {
            name: "Awaiting time (minutes)",
          },
          attendanceTime: {
            name: "Attendance time (minutes)",
          },
          forceAcceptLongAwaitingTimeTickets: {
            name: "Force accept long awaiting time chats",
          },
          userCreation: {
            name: "User creation",
          },
          sendProtocolMessage: {
            name: "Send protocol message",
          },
          sendTransferMessage:{
            name: "Send transfer message",
          },
          sendWaitingAttendant: {
            name: "Send wait attendant message by wrong answer",
          },
          sendNotificationsTo: {
            name: "Send notifications to",
          },
          sendNotifications: {
            name: "Send chat notifications",
          },
        },
      },
      location: {
        errors: {
          permissionNotGranted: "The Geolocation permission is not granted!",
          positionUnavaible: "Your current position is not available in the moment, try again later!",
          timeout: "It was not possible to get your current location, try again later!",
        },
      },
      profile: {
        success: "Profile saved successfully.",
        title: "Company",
        tooltips: {
          turnOnGeolocation: "Turn on Geolocation",
          turnOffGeolocation: "Turn off Geolocation",
        },
        labels: {
          name: "Company's Name",
          description: "Company's Description",
          logo: "Logo",
          logoField: "Tap below to select/replace the logo",
          sendLogoAsSticker: "Send logo as sticker",
          latitude: "Latitude",
          longitude: "Longitude",
          accuracy: "Accuracy",
        },
        buttons: {
          save: "Save",
        },
      },

      messageNotAvailable: {
        deletedFile: "This file is not available anymore, but you can see it on your phone via WhatsApp!",
      },

      messagesList: {
        header: {
          assignedTo: "Assigned to:",
          buttons: {
            return: "Return",
            close: "Close",
            resolve: "Resolve",
            resolveWithoutFarewell: "Resolve without good bye",
            reopen: "Reopen",
            accept: "Accept",
          },
        },
        toast: {
          forwardMessage: {
            limit: "You can forward 20 messages at a time!",
            noSelectedMessages: "You should select at least 1 message to forward!",
          },
          closeTicket: {
            whatsappQueuesNotLoaded: "Loading conversation data before ending. Try again in a few seconds!",
          },
        },
        confirmationModal: {
          openPrivateChat: {
            title: "Would you like to open a chat with",
          },
          resendMessage: {
            title: "Would you like to resend the message?"
          },
        },
      },

      messageBodyWrapper: {
        readMore: "Read More",
        readLess: "Read Less",
      },

      shareMessage: {
        notSupport: "Your browser does not support sharing files!",
        errorSharing: "It was not possible to share the file! Try again later.",
      },

      messagesListChatView: {
        noMessagesText: "No messages in the chat...",
      },

      messagesInput: {
        placeholderOpen: "Type a message or press ''/'' to use the registered quick responses",
        placeholderOpenMobile: "Type a message",
        placeholderClosed: "Reopen or accept this chat to send a message.",
        placeholderBlockedContact: "You cannot send messages because the contact is blocked!",
        placeholderInactiveContact: "You cannot send messages because the contact is inactive!",
        placeholderChannelContact: "You cannot send messages to channel chats!",
        placeholderReadOnlyGroup: "You cannot send messages because you no longer belong to this group.",
        signMessage: "Sign",
        view: "View",
        download: "Download",
        forwardedMessage: "Forwarded message",
        editedMessage: "Edited",
        scrollToSeeMessages: "Scroll to see new messages",

        micAudioConditions: {
          permissionDeniedBySystem: "The mic access has been denied by the device system!",
          lostConnection: "The browser lost connection with your mic. Try again later!",
          micNotEnabled: "Mic not enabled!",
          micNotDetected: "Any mic has been detected!",
        },

        toasts: {
          sendGif: "It was not possible to send the GIF, try again later!",
          audioAccessNotAllowed: "The browser is unallowed to access your microphone. Please, enable permission before sending audios!",
          onlyImageAllowed: "Only images are allowed to be sent as stickers!",
          onlyImageAndVideoAllowed: "Only images and videos are allowed to be sent as view once!",
        },

        tooltips: {
          emojis: "Select emojis",
          record: "Record audio",
          internalMessage: "Internal message",
          moreVert: "More options",
          attach: "Attach file",
          viewOnce: "View once file",
          poll: "Poll",
          location: "Location",
          takePhoto: "Take photo",
          vcard: "Share contact",
          send: "Send message",
          cancel: "Cancel",
          confirm: "Confirm",
          stickers: "Select stickers",
          createStickers: "Create stickers",
          messageNotSent: "Message not sent",
        },
      },
      viewOnceRepliedMessage: {
        photo: "Photo",
        video: "Video",
      },
      audioComponent: {
        accordionTitle: "Transcript",
        tooltips: {
          copy: "Copy",
          copied: "Copied",
        },
      },
      contactDrawer: {
        header: "Contact details",
        buttons: {
          edit: "Edit contact",
          editGroup: "Edit group",
          participants: "Participants",
        },
        extraInfo: "Other information",
        tooltips: {
          copy: "Copy",
          copied: "Copied!",
        },
      },
      ticketOptionsMenu: {
        edit: "Edit",
        block: "Block",
        unblock: "Unblock",
        delete: "Delete",
        note: "Note",
        transfer: "Transfer",
        searchMessage: "Search",
        confirmationModal: {
          title: "Delete chat #",
          titleFrom: "from contact ",
          message: "Attention! All chat's related messages will be lost.",
        },
        buttons: {
          delete: "Delete",
          cancel: "Cancel",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancel",
        },
      },
      ticketOptionsContextMenu: {
        accept: "Accept",
        view: "View",
        markAsUnread: "Mark as Unread",
        markAsRead: "Mark as Read",
        transfer: "Transfer",
        edit: "Edit",
        return: "Return",
        reopen: "Reopen",
        resolve: "Resolve",
        close: "Close Menu",
      },

      phoneNumberOptionsMenu: {
        chat: "Chat with Number",
        copy: "Copy Phone Number",
        close: "Close",
        confirmationModal: {
          title: "Would you like to chat with the number?",
          fields: {
            connection: "Connection",
          },
        },
      },

      messageOptionsMenu: {
        transcript: "Transcript",
        resend: "Resend",
        delete: "Delete",
        reply: "Reply",
        forward: "Forward",
        edit: "Edit",
        copy: "Copy",
        star: "Star",
        unstar: "Unstar",
        speech: "Speech",
        properties: "Properties",
        close: "Close",
        confirmationModal: {
          title: "Delete message?",
          message: "This action cannot be reverted.",
        },
      },
      myProfile: {
        confirmationModal: {
          deleteTitle: "Remove logo",
          deleteMessage: "Are you sure you want to remove your company logo?"
        },
      },
      backendErrors: {
        GENERAL_ERROR: "It was not possible to do it now. Try again later!",
        ERR_NO_OTHER_WHATSAPP:
          "There must be at lest one default WhatsApp connection.",
        ERR_NO_DEF_WAPP_FOUND:
          "No default WhatsApp found. Check connections page.",
        ERR_WAPP_NOT_INITIALIZED:
          "This WhatsApp session is not initialized. Check connections page.",
        ERR_WAPP_CHECK_CONNECTION:
          "Could not check WhatsApp contact. Check connections page.",
        ERR_WAPP_INVALID_CONTACT: "This is not a valid whatsapp number.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Could not download media from WhatsApp. Check connections page.",
        ERR_INVALID_CREDENTIALS: "Authentication error. Please try again.",
        ERR_SENDING_WAPP_MSG:
          "Error sending WhatsApp message. Check connections page.",
        ERR_DELETE_WAPP_MSG: "Couldn't delete message from WhatsApp.",
        ERR_OTHER_OPEN_TICKET:
          "There's already an open chat for this contact.",
        ERR_OTHER_OPEN_TICKET_QUEUE:
          "Chat is assigned to the department:",
        ERR_OTHER_OPEN_TICKET_USER:
          "Chat is assigned to the user:",
        ERR_OTHER_OPEN_TICKET_EXCEPTION:
          "There's already an open chat for this contact. Check out Working On and Pending tab on Active Chats!",
        ERR_SESSION_EXPIRED: "Session expired. Please login.",
        ERR_USER_ALREADY_LOGIN: "User already logged in on another computer",
        ERR_SERVER_MAINTANCE: "The server is down for maintenance, please wait a few minutes",
        ERR_INVALID_TOKEN: "Invalid token. A new one will be assigned on the next request",
        ERR_WAPP_LIMIT_REACHED: "Chip limit reached! Contact support to change your plan.",
        ERR_USER_LIMIT_REACHED: "User limit reached! Contact support to change your plan.",
        ERR_WAPP_NAME_ALREADY_USED: "This whatsapp name is already used.",
        ERR_WAPP_SINGLE_QUEUE: "Must be at least two departments or none selected.",
        ERR_USER_CREATION_DISABLED:
          "User creation was disabled by administrator.",
        ERR_NO_PERMISSION: "You don't have permission to access this resource.",
        ERR_DUPLICATED_CONTACT: "A contact with this number already exists.",
        ERR_NO_SETTING_FOUND: "No setting found with this ID.",
        ERR_NO_CONTACT_FOUND: "No contact found with this ID.",
        ERR_NO_TICKET_FOUND: "No chat found with this ID.",
        ERR_NO_USER_FOUND: "No user found with this ID.",
        ERR_NO_WAPP_FOUND: "No WhatsApp found with this ID.",
        ERR_CREATING_MESSAGE: "Error while creating message on database.",
        ERR_CREATING_TRANSFERENCE_OBSERVATION_MESSAGE: "Error while creating transference observation message on database. Try again later!",
        ERR_CREATING_INTERNAL_MESSAGE: "Error while creating internal message on database. Try again later!",
        ERR_CREATING_TICKET: "Error while creating chat on database. Try again later!",
        ERR_MYPROFILE_INVALID_NAME: "Invalid profile name.",
        ERR_UPDATE_MYPROFILE: "It was not possible to update the profile. Try again later!",
        ERR_FETCH_WAPP_MSG:
          "Error fetching the message in WhatsApp, maybe it is too old or it has been deleted by the contact.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "This color is already in use, pick another one.",
        ERR_WAPP_GREETING_REQUIRED:
          "Greeting message is required if there exists departments.",
        ERR_FILE_SIZE_UPLOAD:
          "WhatsApp Web does not support files with more than 15MB.",
        ERR_FILE_NOT_SUPPORTED:
          "This kind of file is not supported!",
        ERR_REACHED_MAIN_QUEUES_LIMIT: 
          "It is not possible to register more than 3 Departments!",
        LABEL_NOT_SELECTED:
          "Label not selected!",
        QUEUE_NOT_SELECTED:
          "Department not selected!",
        SUBQUEUE_NOT_SELECTED:
          "Sector not selected!",
        DASHBOARD_INITIAL_FINAL_DATE_CLEAN:
          "You can not clear the initial and the final date!",
        DASHBOARD_INITIAL_DATE_AFTER_FINAL_DATE:
          "The initial date cannot be after the final date!",
        BUSINESS_HOURS_INITIAL_HOUR_EQUAL_FINAL_HOUR:
          "The initial hour cannot be equal to the final hour if the company is open! Check out if all hours has been correctly filled.",
        BUSINESS_HOURS_INITIAL_HOUR_AFTER_FINAL_HOUR:
          "The initial hour cannot be after the final hour! Check out if all hours has been correctly filled.",
        BUSINESS_HOURS_PERSONALIZED_MESSAGES_EMPTY:
          "Personalized Message must have more than 3 characters when it is enabled! Check out whether all messages has been filled up correctly.",
        INTERVAL_HOURS_EQUALS_AFTER_FINAL_HOUR:
          "Initial Break Time cannot be equals or after Final Break Time.",
        INTERVAL_HOURS_NOT_BETWEEN_BUSINESS_HOURS:
          "Interval Hours are not between Business Hours.",
        SERIAL_NUMBER_NOT_INFORMED:
          "Serial Number not informed!",
        RESALE_SERIAL_NUMBER_NOT_INFORMED:
          "Resale Serial Number not informed!",
        ERR_STAR_MSG:
          "Error to star message!",
        ERR_UNSTAR_MSG:
          "Error to unstar message! Try again later.",
        ERR_EMAIL_NOT_FOUND: 
          "Email not registered!",
        ERR_EMAIL_ALREADY_EXISTS:
          "An user with this email already exists!",
        ERR_LABEL_NAME_ALREADY_EXISTS:
          "This name is already in use, pick another one.",
        ERR_SUBQUEUE_INVALID_DESCRIPTION:
          "Provide a description for the subqueue!",
        ERR_INFO_NOT_FOUND:
          "It was not possible to load the message info. Probably the message does not exist on the phone!",
        ERR_PAYMENT_REQUIRED:
          "There are expired tickets for your account. Please, contact our support for more information!",
        ERR_SERVER_IN_MAINTENANCE:
          "The server is in maintenance in the moment, please, try again in a few minutes!",
        ERR_SERVER_MIGRATION:
          "Your account is over server migration, please, try again in a few minutes!",
        EDIT_MESSAGE_LIMIT_MINUTES_REACHED:
          "It is not possible to edit messages sent more than 15 minutes ago!",
        EDIT_MESSAGE_INTERNAL_ERROR:
          "An error has occurred while the message was been updated. Try again later!",
        NO_MESSAGE_FOUND_BY_ID:
          "No message found by the ID sent!",
        ERR_COMPANY_LOCATION_NOT_REGISTERED:
          "Localization is not informed at the Company's Profile!",
        ERR_FORCE_ACCEPT_LONG_AWAITING_TIME_TICKETS: 
          "There are chats with long awaiting time. It is needed to to finish them first!",
        ERR_OPENAI_TRANSCRIBE_AUDIO_NOT_FOUND: "Audio not found to be transcribed! Try again later.",
        ERR_TRANSCRIBE_AUDIO: "It was not possible to transcribe the audio. Try again later!",
        ERR_INVALID_POLL_DATA:
          "Invalid poll data! Check out if all required fields has been filled!",
        ERR_INVALID_POLL_MESSAGE:
          "Poll not found! Try again later.",
        ERR_INVALID_CPF:
          "Nonexistent CPF!",
        ERR_INVALID_CNPJ:
          "Nonexistent CNPJ!",
        ERR_INVALID_BIRTHDAY:
          "Birthday cannot be after today!",
        ERR_CATEGORY_NAME_ALREADY_EXISTS:
          "Duplicated category name!",
        ERR_QUEUE_NAME_ALREADY_EXISTS:
          "Duplicated queue name!",
        ERR_UNBLOCK_CONTACT:
          "An error occurred while unblocking the contact. Try again later!",
        ERR_BLOCK_CONTACT:
          "An error occurred while blocking the contact. Try again later!",
        ERR_SETTING_NOT_FOUND: "Setting not found!",
        ERR_GROUP_NOTIFICATION: "🤔 It was not possible to handle group notification!",
        ERR_SENDING_WAPP_REACTION: "Error sending WhatsApp reaction. Check connections page.",
        FAILED_TO_JOIN_GROUP_BY_INVITE_CODE: "Failed to join group. Request another invite to the group's administrator!",
        ERR_SEND_POLL_VOTE: "It was not possible to vote on the poll, try again later!",
        ERR_MARK_CHAT_READ_UNREAD: "It was not possible to mark the chat as read/unread. Try again later!",
        ERR_IMPORT_MESSAGES: "It was not possible to import messages. Try again later!",
        ERR_SENDING_LIST_RESPONSE_MSG: "Error sending WhatsApp List Response message. Check connections page!",
        ERR_SENDING_BUTTON_RESPONSE_MSG: "Error sending WhatsApp Button Response message. Check connections page!",
        ERR_USER_NOT_ALLOWED: "User not allowed to do the operation!",
        NO_PERMISSION_UPDATE_GROUP_NAME: "You do no have permission to update group's name. Ask the group's admin!",
        ERR_FORWARD_MESSAGE: "It was not possible to forward the message. Try again later!",
        ERR_MARK_INTERNAL_CHAT_MESSAGES_AS_READ: "It was not possible to mark the messages as read!",
        ERR_CREATING_PHONE_NUMBER_MESSAGE_TICKET: "It was not possible to create a chat with the phone number message!",
        ERR_FETCHING_GROUP_PARTICIPANTS: "It was not possible to fetch group participants. Try again later!",
        ERR_FETCHING_REMAINING_GROUP_TICKETS: "It was not possible to fetch all remaning group chats. Try again later!",
        ERR_FETCH_MESSAGES: "It was not possible to fetch chat messages. Try again later!",
        ERR_WAPP_BECOMING_NOT_DEFAULT: "It is not possible to directly turn a default connection to non-default!",
        ERR_SEARCH_MESSAGE: "It was not possible to search for messages. Try again later!",
        NOT_ALLOWED_TO_DELETE_MESSAGE: "You do not have permission to delete messages not sent by you!",
        NOT_ALLOWED_TO_EDIT_MESSAGE: "You do not have permission do edit messages not sent by you!",
        ERR_MARK_BUTTON_LIST_AS_INTERACTED: "It was not possible to mark button/list as interacted. Try again later!",
        ERR_CREATE_EVENT_DETAILS: "It was not possible to create event details association to the message!",
        ERR_BUTTON_OPTION: "It was not possible to create button option association to the message!",
        ERR_LIST_OPTION: "It was not possible to create list option association to the message!",
        ERR_POLL_OPTION: "It was not possible to create poll option association to the message!",
        ERR_DISPATCH_CREATE: "It was not possible to create dispatch. Try again later!",
        ERR_DISPATCH_UPDATE: "It was not possible to update dispatch. Try again later!",
      },
    },
  },
};

export { messages };
