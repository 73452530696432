import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import toastError from "../../errors/toastError";
import useWhatsApps from "../../hooks/useWhatsApps";

const OpenPrivateChatModal = ({
  onClose,
  onCloseAll,
  open,
  selectedContactId,
  selectedContactName,
  selectedContactType = 0
}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const history = useHistory();

  const { user } = useContext(AuthContext);
  const { whatsApps: whatsappsList } = useWhatsApps();

  const [selectedWhatsappId, setSelectedWhatsappId] = useState("");
  const [userQueues, setUserQueues] = useState([]);



  //  *****************
  //  ** Use Effects **
  //  *****************
  // useEffect(() => {
  //   if (open && whatsappsList.length === 1) setSelectedWhatsappId(whatsappsList[0].id);
  // }, [open, whatsappsList]);

  useEffect(() => {
    if (open) {
      if (user.whatsapp?.id) setSelectedWhatsappId(user.whatsapp.id);
      else if (whatsappsList.length === 1) setSelectedWhatsappId(whatsappsList[0].id);
    }
  }, [open, user, whatsappsList]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchUserQueuesIds = async () => {
        try {
          const { data } = await api.get(`/userQueuesIds/${user.id}`);
          setUserQueues(data);
        } catch (exception) {
          console.log("Open Private Chat Modal Use Effect Exception:", exception);
          
          try { toastError(exception); }
          catch (exception) { console.log("Open Private Chat Modal Use Effect Caught Another Exception:", exception); }
        }
      };
      fetchUserQueuesIds();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [user.id]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();
    setSelectedWhatsappId("");
  };

  const handleCloseAll = () => {
    onCloseAll();
    setSelectedWhatsappId("");
  };

  const handleNewChat = async () => {
    if (!selectedContactId) return;

    const whatsappIdNotSelected = selectedContactType === 0 && (!selectedWhatsappId || selectedWhatsappId === undefined || selectedWhatsappId === "");
    
    if (whatsappIdNotSelected) {
      toast.info(i18n.t("newTicketModal.toasts.whatsappNotSelected"));
    } else {
      try {
        // ***---- Creating New Ticket ----***
        const { data: ticket } = await api.post("/tickets", {
          contactId: selectedContactId,
          isCreatedByUser: true,
          isBotInteractionFinished: true,
          status: "open",
          userId: user.id,
          whatsappId: selectedWhatsappId
        });

        handleCloseAll();

        // ***---- Ticket Access Validation ----***
        const { data: adminCanViewOtherChats } = await api.get("/settings/adminCanViewOtherChats");
        const pendingTicketCondition = ticket.status === "pending" && (ticket.queueId === null || userQueues.includes(ticket.queueId));
        const openTicketAndAdminUserCondition = ticket.status === "open" && user.profile === "admin" && adminCanViewOtherChats.value === "enabled";
        const openTicketAndCommonUserCondition = ticket.status === "open" && ticket.userId === user.id;

        // - User can see Ticket
        if (pendingTicketCondition || openTicketAndAdminUserCondition || openTicketAndCommonUserCondition) {
          localStorage.setItem("tabOpenTickets", ticket.status);
          history.push(`/tickets/${ticket.id}`);
        }

        // - User cannot see Ticket
        else {

          // - Pending Ticket in Another Queue
          if (ticket.status === "pending") {
            toast.info(`
              ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET")}
              ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET_QUEUE")}
              ${ticket.queue?.name}
            `);
          }

          // - Ticket with Another User
          else {
            toast.info(`
              ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET")}
              ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET_USER")}
              ${ticket.user?.name}
            `);
          }
        }
      } catch (exception) {
        console.log("Handle New Chat Exception:", exception);
        try { toastError(exception); }
        catch (exception) { console.log("Handle New Chat Caught Another Exception:", exception); }
      }
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <ConfirmationModal
      title={`${i18n.t("contacts.confirmationModal.openPrivateChat.title")} ${selectedContactName}?`}
      open={open}
      onClose={handleClose}
      onConfirm={handleNewChat}
    >
      {selectedContactType === 0 && (
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel>{i18n.t("newTicketModal.connection")}</InputLabel>

          <Select
            fullWidth
            label={i18n.t("newTicketModal.connection")}
            onChange={(event) => setSelectedWhatsappId(event.target.value)}
            value={selectedWhatsappId}
          >
            <MenuItem value={""}>&nbsp;</MenuItem>
            {whatsappsList.map(whatsapp => (
              <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </ConfirmationModal>
  );
};

export default OpenPrivateChatModal;
