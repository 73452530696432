import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import {
	Avatar,
	Badge,
	Divider,
	ListItemAvatar,
	ListItem,
	ListItemText,
	Tooltip,
	Typography,
} from "@material-ui/core";
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import ReplyOutlinedIcon from '@material-ui/icons/ReplyOutlined';
import SimCardOutlinedIcon from '@material-ui/icons/SimCardOutlined';
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';
import clsx from "clsx";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import api from "../../services/api";
import BezinhoImg from '../../assets/bezinho.svg';
import botIcon from "../../assets/ticketsTypes/bot.svg";
import chatIcon from '../../assets/ticketsTypes/chat.png';
import ContactTagsModal from "../ContactTagsModal";
import EditTicketModal from "../EditTicketModal";
import internalChatIcon from "../../assets/ticketsTypes/internalChatIcon.png";
import InternalChatImg from "../../assets/internalChat.png";
import MarkdownWrapper from "../MarkdownWrapper";
import millisecondsToTime from "../../utils/millisecondsToTime";
import TicketOptionsContextMenu from "../TicketOptionsContextMenu";
import toastError from "../../errors/toastError";
import TransferTicketModal from "../TransferTicketModal";
import truncateString from "../../utils/truncateString";
import whatsappIcon from '../../assets/ticketsTypes/whatsapp.png';
import WhatsappOfficialImg from "../../assets/whatsappOfficial.jpg";

const useStyles = makeStyles(theme => ({
	horizontalFlip: { rotate: "y 180deg", fontSize: "20px", },
	svgIcons: { borderRadius:"20px", fontSize: "15px", },
	ticket: { borderRadius:"20px", position: "relative", },
	pendingTicket: { borderRadius:"20px", cursor: "pointer", },

	noTicketsDiv: {
		borderRadius:"20px",
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		borderRadius:"20px",
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		borderRadius:"20px",
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: { borderRadius: "20px", display: "flex", justifyContent: "space-between", },

	contactBadgeWrapper: {
		borderRadius: "20px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap: "1.65em"
	},

	newBadge: { borderRadius: "20px", alignSelf: "center", marginRight: 8, marginLeft: "auto", },

	contactNameAndDevice: { display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", },
	ticketType: { width: "20px", },
	lastMessageTime: { borderRadius:"20px", justifySelf: "flex-end", },

	badgeList: {
		borderRadius:"20px",
		adding: "3px",
		color: "#f6f6ff",
		fontSize: "10px",
		
		display: "flex",
		gap: "3px",
		alignItems: "center",
	},

	customBadge: {
		border: `1px solid ${theme.palette.primary.main}`,
		color: theme.palette.text.primary,

		padding: "3px",
		paddingLeft: "10px",
		paddingRight: "10px",
		borderRadius: "15px",
		fontSize: "10px",
		width: "fit-content",
		display: "flex",
		gap: "3px",
		alignItems: "center",
	},
	
	customBadgeHover: {
		transition: 'transform 0.3s',
		'&:hover': { transform: 'translateY(-2px)', backgroundColor: `${theme.palette.primary.hoverBadge}`, }
	},

	customBadgeAwaitingTime: {
		border: `1px solid ${theme.palette.primary.main}`,
		color: theme.palette.text.primary,

		padding: "3px",
		paddingLeft: "10px",
		paddingRight: "10px",
		borderRadius: "15px",
		fontSize: "10px",
		width: "fit-content",
		display: "flex",
		gap: "3px",
		alignItems: "center",
	},

	customLabelBadge: {
		transition: "all 0.3s ease",
		marginBottom: "2px",
		"&:hover": { cursor: "pointer", transform: "translateY(-5px)", opacity: 0.8, },

		color: theme.palette.text.primary,

		padding: "3px",
		paddingLeft: "10px",
		paddingRight: "10px",
		borderRadius: "15px",
		fontSize: "10px",
		width: "fit-content",
		display: "flex",
		gap: "3px",
		alignItems: "center",		
	},

	customBadgeAwaitingTimeAlert: {
		backgroundColor: theme.palette.primary.main,
		color: "#f5f5ff",

		padding: "3px",
		paddingLeft: "10px",
		paddingRight: "10px",
		borderRadius: "15px",
		fontSize: "10px",
		width: "fit-content",
		display: "flex",
		gap: "3px",
		alignItems: "center",
	},

	chipBadge: {
		border: `1px solid ${theme.palette.primary.main}`,
		padding: "3px",
		paddingLeft: "10px",
		paddingRight: "10px",
		borderRadius: "15px",
		color: theme.palette.text.primary,
		fontSize: "10px",
		width: "fit-content",
		marginRight: 0,
		marginLeft: "auto",

		display: "flex",
		gap: "3px",
		alignItems: "center",
		justifySelf: "flex-end",
	},

	closedBadge: { alignSelf: "center", justifySelf: "flex-end", marginRight: 32, marginLeft: "auto", },
	contactLastMessage: { paddingRight: 20, width: "100%" },
	expirationLabel: { color:"textSecondary", fontSize: "10px", },
	badgeStyle: { color: "white", backgroundColor: green[500], },
	acceptButton: { borderRadius:"20px", position: "absolute", left: "50%", },

	ticketQueueColor: {
		borderRadius:"20px",
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},

	userTag: {
		position: "absolute",
		marginRight: 5,
		right: 5,
		bottom: 5,
		backgroundColor: theme.palette.background.default,
		color: theme.palette.primary,
		border: "1px solid #CCC",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 10,
		fontSize: "0.9em"
	},
}));

const TicketListItem = ({ ticket, ticketsList, setTabOpen, resetAudioPlayer }) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const history = useHistory();
	const isMounted = useRef(true);
	const { ticketId } = useParams();
	const { user } = useContext(AuthContext);

	const [anchorEl, setAnchorEl] = useState(null);
	const ticketOptionsContextMenuOpen = Boolean(anchorEl);

	const [attendanceTimeSetting, setAttendanceTimeSetting] = useState(30);
	const [awaitingTimeSetting, setAwaitingTimeSetting] = useState(30);
	const [contactTagsModalOpen, setContactTagsModalOpen] = useState(false);
	const [prioritizeUserSetting, setPrioritizeUserSetting] = useState("disabled");
	const [prioritizeUserTimeSetting, setPrioritizeUserTime] = useState(2);
	const [chatExpirationSetting, setChatExpiration] = useState(0);

	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const [editTicketModalOpen, setEditTicketModalOpen] = useState(false);
	


	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		return () => { isMounted.current = false; };
	}, []);

	useEffect(() => {
		const fetchSettings = async () => {
			try {
				const apiCalls = [
					api.get("/settings/attendanceTime"),
					api.get("/settings/awaitingTime"),
					api.get("/settings/chatExpiration"),
					api.get("/settings/prioritizeUser"),
					api.get("/settings/prioritizeUserTime"),
				];

				const [
					AttendanceTimeResponse,
					AwaitingTimeResponse,
					ChatExpirationResponse,
					PrioritizeUserResponse,
					PrioritizeUserTimeResponse
				] = await Promise.all(apiCalls);

				if (
					AttendanceTimeResponse &&
					AttendanceTimeResponse !== undefined &&
					AttendanceTimeResponse.data &&
					AttendanceTimeResponse.data !== undefined
				) {
					setAttendanceTimeSetting(parseInt(AttendanceTimeResponse.data.value)); // getting "attendanceTime" setting
				}

				if (
					AwaitingTimeResponse &&
					AwaitingTimeResponse !== undefined &&
					AwaitingTimeResponse.data &&
					AwaitingTimeResponse.data !== undefined
				) {
					setAwaitingTimeSetting(parseInt(AwaitingTimeResponse.data.value, 10)); // getting "awaitingTime" setting
				}

				if (
					ChatExpirationResponse &&
					ChatExpirationResponse !== undefined &&
					ChatExpirationResponse.data &&
					ChatExpirationResponse.data !== undefined
				) {
					setChatExpiration(parseInt(ChatExpirationResponse.data.value, 10)); // getting "chatExpiration" setting
				}

				if (
					PrioritizeUserResponse &&
					PrioritizeUserResponse !== undefined &&
					PrioritizeUserResponse.data &&
					PrioritizeUserResponse.data !== undefined
				) {
					setPrioritizeUserSetting(PrioritizeUserResponse.data.value); // getting "prioritizeUser" setting
				}

				if (
					PrioritizeUserTimeResponse &&
					PrioritizeUserTimeResponse !== undefined &&
					PrioritizeUserTimeResponse.data &&
					PrioritizeUserTimeResponse.data !== undefined
				) {
					if (PrioritizeUserTimeResponse.data.value === "infinity") {
						setPrioritizeUserTime(PrioritizeUserTimeResponse.data.value); // getting "prioritizeUserTime" setting
					} else {
						setPrioritizeUserTime(parseInt(PrioritizeUserTimeResponse.data.value, 10)); // getting "prioritizeUserTime" setting
					}
				}
			} catch (error) {
				console.log("Ticket List Item Use Effect Error:", error);

				try {
					toastError(error);
				} catch (error) {
					console.log("Ticket List Item Use Effect Catch Error:", error);
				}
			}
		};

		fetchSettings();
	}, []);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleOpenContactTagsModal = () => { setContactTagsModalOpen(true); };
	const handleCloseContactTagsModal = () => { setContactTagsModalOpen(false); };

	const handleOpenEditTicketModal = () => { setEditTicketModalOpen(true); }
	
	const handleCloseEditTicketModal = () => {
		if (isMounted.current) { setEditTicketModalOpen(false); }
		setEditTicketModalOpen(false);
	};
	
	const handleOpenTransferModal = () => { setTransferTicketModalOpen(true); };
	
	const handleCloseTransferTicketModal = () => {
		if (isMounted.current) { setTransferTicketModalOpen(false); }
	};

	const handleOpenTicketOptionsMenu = (e, ticket) => {
		if (!ticket || ticket === undefined) { return; }
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = () => {
		setAnchorEl(null);
	}

	const handleContextMenu = (e, ticket) => {
		e.preventDefault();
		ticket && ticket.status !== undefined && handleOpenTicketOptionsMenu(e, ticket);
	};

	const formatSelectedTicketContactIdForLocalStorage = (ticketId, ticketType, contactId) => {
		const ticketTypeNumber = parseInt(ticketType, 10);

		if (ticketTypeNumber === 0) return contactId;
		else if (ticketTypeNumber === 1) return `0-note-${ticketId}`;
		else if (ticketTypeNumber === 2) return `0-chatBot-${ticketId}`;
		else if (ticketTypeNumber === 3) return `0-internalChat-${ticketId}`;
	};

	const handleSelectTicket = (id, type, status, contactId) => {
		localStorage.setItem(
			"selectedTicketContactId",
			formatSelectedTicketContactIdForLocalStorage(id, type, contactId)
		);

		localStorage.setItem("tabOpenTickets", status);
		
		if (localStorage.getItem("selectedTicketContactId") === localStorage.getItem("currentPlayingAudioContactId")) resetAudioPlayer();
		
		if (status === "open" || status === "pending" || status === "group" || status === "channel") history.push(`/tickets/${id}?tab=open`);
		else if (status === "closed") history.push(`/tickets/${id}?tab=closed`);
	};

	const timeAwaiting = millisecondsToTime(new Date() - new Date(ticket.createdAt));
	let [timeAwaitingNumber, timeAwaitingMetric] = [parseInt(timeAwaiting.split(" ")[0]), timeAwaiting.split(" ")[1]];
	const secondMetrics = [i18n.t("ticketsList.timeAwaitSecond"), i18n.t("ticketsList.timeAwaitSecond") + "s"];
	const hourDayMetrics = [
		i18n.t("ticketsList.timeAwaitHour"), i18n.t("ticketsList.timeAwaitDay"),
		i18n.t("ticketsList.timeAwaitHour") + "s", i18n.t("ticketsList.timeAwaitDay") + "s"
	];

	timeAwaitingNumber = hourDayMetrics.includes(timeAwaitingMetric) ? timeAwaitingNumber * 60 : timeAwaitingNumber;

	let timeInAttendance;

	// se ticket já foi fechado, tempo de atendimento será de quando um usuário
	// o pegou até quando o ticket foi atualizado pela última vez
	if (ticket.userId !== null && ticket.status === "closed") {
		timeInAttendance = millisecondsToTime(new Date(ticket.isClosedAt) - new Date(ticket.withUserAt));
	}

	// se ticket consta em aberto, tempo de atendimento será de quando um usuário
	// o pegou até o momento atual em que está se vendo o tempo
	else if (ticket.userId !== null && ticket.status === "open") {
		timeInAttendance = millisecondsToTime(new Date() - new Date(ticket.withUserAt));
	}

	// caso o ticket ainda não tenha um usuário vinculado, o tempo de atendimento
	// será zero
	else {
		timeInAttendance = 0;
	}

	let timeAttendanceNumber = timeInAttendance === 0 ? 0 : parseInt(timeInAttendance.split(" ")[0]);
	let timeAttendanceMetric = timeInAttendance === 0 ? 0 : timeInAttendance.split(" ")[1];

	if (timeInAttendance !== 0) {
		timeAttendanceNumber = hourDayMetrics.includes(timeAttendanceMetric)
			? timeAttendanceNumber * 60
			: timeAttendanceNumber;
	}

	const { whatsApps } = useContext(WhatsAppsContext);
	const showChipBadge = whatsApps?.length > 1;

	let ticketTypeIcon;
	if (ticket.type === 0) ticketTypeIcon = whatsappIcon;
	else if (ticket.type === 1) ticketTypeIcon = chatIcon;
	else if (ticket.type === 2) ticketTypeIcon = botIcon;
	else ticketTypeIcon = internalChatIcon;

	let userExists;

	try {
		userExists = ticket.type === 0 ? ticket?.contact?.users.some(contactUser => contactUser.id === user.id) : [];
	} catch (error) {
		userExists = [];
	}

	const showTicketFlag = (
		prioritizeUserSetting === "disabled"
		|| (
			ticket?.status !== "pending"
			|| ticket?.type === 1 || ticket?.type === 2
			|| (ticket?.type === 0 && (
				ticket?.contact && ticket?.contact.users &&
				(
					(ticket?.contact.users.length === 0)
					|| (ticket?.contact.users.length > 0 && userExists)
					|| (
							ticket?.contact.users.length > 0
							&& prioritizeUserTimeSetting !== "infinity"
							&& prioritizeUserTimeSetting * 60 * 1000 < (new Date() - new Date(ticket.createdAt))
						 )
				)
			))
		)
	);



	// 	************
	// 	** Return **
	// 	************
	if (showTicketFlag) {
		return (
			<React.Fragment key={ticket.id}>
				<TicketOptionsContextMenu
					ticket={ticket}
					ticketsList={ticketsList}
					timeAwaitingFlag={awaitingTimeSetting !== -1 && timeAwaitingNumber >= awaitingTimeSetting && !secondMetrics.includes(timeAwaitingMetric)}
					awaitingTimeSetting={awaitingTimeSetting}
					transferTicketModalOpen={handleOpenTransferModal}
					editTicketModalOpen={handleOpenEditTicketModal}
					menuOpen={ticketOptionsContextMenuOpen}
					handleClose={handleCloseTicketOptionsMenu}
					anchorEl={anchorEl}
					setTabOpen={setTabOpen}
				/>

				{ticket.status === "open" && (
					<TransferTicketModal
						modalOpen={transferTicketModalOpen}
						onClose={handleCloseTransferTicketModal}
						ticketid={ticket.id}
						ticketType={ticket.type}
						ticketWhatsappId={ticket.whatsappId}
					/>
				)}

				{(ticket.status === "open" || ticket.status === "pending") && (
					<EditTicketModal
						modalOpen={editTicketModalOpen}
						onClose={handleCloseEditTicketModal}
						ticket={ticket}
						ticketStatus={ticket.status}
					/>
				)}

				<Tooltip
					arrow
					placement="left"
					title={ticket.queue?.name || "Sem fila"}
					PopperProps={{ disablePortal: true }}
				>
					<ListItem
						dense
						button
						onClick={e => {handleSelectTicket(ticket.id, ticket.type, ticket.status, ticket.contact ? ticket.contact.id : "0");}}
						onContextMenu={e => handleContextMenu(e, ticket)}
						selected={ticketId && +ticketId === ticket.id}
						className={clsx(classes.ticket, { [classes.pendingTicket]: ticket.status === "pending", })}
						style={{ 
							"-webkit-box-shadow": `inset -3px -4px ${ticket.queue?.color || "#7C7C7C"}`,
							"-moz-box-shadow": `inset -3px -4px ${ticket.queue?.color || "#7C7C7C"}`,
							boxShadow: `inset -3px -4px ${ticket.queue?.color || "#7C7C7C"}`,
						}}
					>

						<ListItemAvatar>
							{(ticket.type === 0 || ticket.type === 1) && (
								<Avatar src={ticket?.contact?.name === "WhatsApp Official ✔️" ? WhatsappOfficialImg : ticket?.contact?.profilePicUrl} />
							)}

							{(ticket.type === 2 || ticket.type === 3) && (
								<Avatar src={ticket.type === 2 ? BezinhoImg : InternalChatImg} />
							)}
						</ListItemAvatar>

						<ListItemText
							disableTypography
							primary={
								<span className={classes.contactNameWrapper}>
									<span className={classes.contactNameAndDevice}>
										<img className={classes.ticketType} src={ticketTypeIcon} alt="Ticket Logo" />

										{ticket.status === "pending" && ticket.isReturned && (
											<Tooltip
												title={i18n.t("ticketsList.isReturned")}
												placement="bottom-start"
												arrow
												PopperProps={{ disablePortal: true }}
											>
												<ReplyOutlinedIcon className={classes.horizontalFlip} />
											</Tooltip>
										)}

										{ticket.isTransfered && (
											<Tooltip
												title={i18n.t("ticketsList.isTransfered")}
												placement="bottom-start"
												arrow
												PopperProps={{ disablePortal: true }}
											>
												<SyncAltOutlinedIcon className={classes.horizontalFlip} />
											</Tooltip>
										)}

										{ticket.hasMessageFromPhone && (
											<Tooltip
												title={i18n.t("tickets.notification.hasMessageFromPhone").replace('@', Math.round(((new Date() - new Date(ticket.updatedAt)) / 60000 - (chatExpirationSetting + 1)) * -1))}
												placement="bottom-start"
												arrow
												PopperProps={{ disablePortal: true }}
											>
												<PhoneAndroidOutlinedIcon color="red" className={classes.svgIcons}/>
											</Tooltip>
										)}

										<Typography component="span" variant="body2" color="textPrimary" style={{wordBreak: "break-word"}}>
											<>
											{ticket.contact && (truncateString(ticket.contact.name, 27))}
											{!ticket.contact && (truncateString(ticket.noteName, 27))}
											</>
										</Typography>
									</span>

									{ticket.lastMessage && (
										<Typography className={classes.lastMessageTime} component="span" variant="body2" color="textSecondary">
											{
											isSameDay(parseISO(ticket.updatedAt), new Date())
												? (<>{format(parseISO(ticket.updatedAt), "HH:mm")}</>)
												: (<>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>)
											}
										</Typography>
									)}										
								</span>
							}
							secondary={
								<span className={classes.contactBadgeWrapper}>
									
									<Typography className={classes.contactLastMessage} noWrap component="span" variant="body2" color="textSecondary">
										{
										ticket.lastMessage
											? (<MarkdownWrapper shouldFormat={false}>{ticket.lastMessage}</MarkdownWrapper>)
											: (<br />)
										}

										{
											ticket.type === 0 &&
											ticket.contact &&
											ticket.contact !== undefined &&
											ticket.contact.labels &&
											ticket.contact.labels !== undefined &&
											ticket.contact.labels.length !== undefined &&
											ticket.contact.labels.length > 0 &&
											(	
												<>
													<ContactTagsModal
														open={contactTagsModalOpen}
														onClose={handleCloseContactTagsModal}
														labelsList={ticket.contact.labels}
													/>

													<div
														className={ classes.customLabelBadge}
														style={{ border: `1px solid ${ticket.contact.labels[0].color}`} }													
														onClick={handleOpenContactTagsModal}
													>
														{truncateString(ticket.contact.labels[0].name)}
													</div>						
												</>
											)
										}
										

										<div className={classes.badgeList}>

											{!ticket.userId && !ticket.isGroup && (
												<>
												{(awaitingTimeSetting === -1
												|| timeAwaitingNumber < awaitingTimeSetting
												|| secondMetrics.includes(timeAwaitingMetric)) 
												&& (
													<div className={classes.customBadgeAwaitingTime}>
														<AccessTimeOutlinedIcon className={classes.svgIcons} /> {timeAwaiting}
													</div>
												)}

												{awaitingTimeSetting !== -1
												&& timeAwaitingNumber >= awaitingTimeSetting
												&& !secondMetrics.includes(timeAwaitingMetric)
												&& (
													<div className={classes.customBadgeAwaitingTimeAlert}>
														<AccessTimeOutlinedIcon className={classes.svgIcons} /> {timeAwaiting}
													</div>
												)}
												</>
											)}

											{ticket.userId && ticket.withUserAt && (
												<>
												{(attendanceTimeSetting === -1
												|| timeAttendanceNumber < attendanceTimeSetting
												|| secondMetrics.includes(timeAttendanceMetric))
												&& (
													<div className={classes.customBadgeAwaitingTime}>
														<AccessTimeOutlinedIcon className={classes.svgIcons} /> {timeInAttendance}
													</div>
												)}

												{attendanceTimeSetting !== -1
												&& timeAttendanceNumber >= attendanceTimeSetting
												&& !secondMetrics.includes(timeAttendanceMetric)
												&& (
													<div className={classes.customBadgeAwaitingTimeAlert}>
														<AccessTimeOutlinedIcon className={classes.svgIcons} /> {timeInAttendance}
													</div>
												)}
												</>
											)}

											{ticket.directClosedById && user.profile === "admin" && ticket.status === "closed" && (
												<div className={classes.customBadge}>
													<CloseOutlinedIcon className={classes.svgIcons}/> {ticket.directClosedByUser?.name}
												</div>
											)}

											{ticket.directClosedById && user.profile === "user" && ticket.status === "closed" && (
												<div className={classes.customBadge}>
													<CloseOutlinedIcon className={classes.svgIcons}/>
												</div>
											)}
											
											{ticket.userId && ticket.type !== 3 && user.profile === "admin" && (
												<div className={classes.customBadge}>
													<PersonOutlinedIcon className={classes.svgIcons}/> {ticket.user?.name}
												</div>
											)}

											{ticket.type === 3 && (
												<div className={classes.customBadge}>
													<PersonOutlinedIcon className={classes.svgIcons} />
													{ticket.user?.name} || {ticket.userInternalChat?.name}
												</div>
											)}

											{ticket.subqueueId && (
												<div className={classes.customBadge}>
													<ChatBubbleOutlineOutlinedIcon className={classes.svgIcons} /> {ticket.subqueue?.name}
												</div>
											)}

											{ticket.queueId && !ticket.subqueueId && (
												<div className={classes.customBadge}>
													<ChatBubbleOutlineOutlinedIcon className={classes.svgIcons} /> {ticket.queue?.name}
												</div>
											)}

											{ticket.whatsappId && showChipBadge && (
												<div className={classes.chipBadge}>
													<SimCardOutlinedIcon className={classes.svgIcons} /> {ticket.whatsapp?.name}  
												</div>
											)}
										</div>
									</Typography>

									<Badge
										className={classes.newBadge}
										badgeContent={
											(() => {
												// - Internal Chats when Logged In User has Created the Chat
												if (ticket.type === 3 && user.id === ticket.userId) {
													return ticket.isMarkedAsUnread ? "" : 0;
												}
												
												// - Internal Chats when Logged In User has not Created the Chat
												else if (ticket.type === 3 && user.id === ticket.userIdInternalChat) {
													return ticket.isMarkedAsUnreadInternalChat ? "" : 0;
												}

												// Whatsapp Tickets (Groups with Me Mentioned)
												else if (ticket.status === "group" && ticket.isMeMentioned) {
													return "@";
												}
												
												// - Notes, Bot Chats and Whatsapp Tickets
												else {

													// - Whatsapp Channel Tickets
													if (ticket.status === "channel" && ticket.unreadMessages !== 0) {
														return "";
													}

													// - Tickets with Unread Messages
													else if (ticket.unreadMessages !== 0 && ticket.unreadMessages) {
														return ticket.unreadMessages;
													}

													// - Tickets Marked as Unread/Read
													else {
														return ticket.isMarkedAsUnread ? "" : 0;
													}
												}
											})()
										}
										classes={{ badge: classes.badgeStyle, }}
									/>
								</span>
							}
						/>

					</ListItem>
				</Tooltip>

				<Divider variant="inset" component="li" />
			</React.Fragment>
		);
	}
	else {
		return (<React.Fragment key={ticket.id}></React.Fragment>);
	}
};

export default TicketListItem;
