import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles(theme => ({
  floatingButton: {
    transition: "transform 0.3s",
    "&:hover": { transform: "translateY(-5px)", },
  },
}));

const ReopenTicketModal = ({ modalOpen, onClose, setTabOpen, ticket }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { whatsApps: whatsAppsList } = useWhatsApps();
  const defaultWhatsapp = whatsAppsList.length === 1 ? whatsAppsList[0] : null;

  const [selectedWhatsappId, setSelectedWhatsappId] = useState("");

  const [userQueues, setUserQueues] = useState([]);




  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    if (modalOpen) {
      if (user.whatsapp?.id) {
        setSelectedWhatsappId(user.whatsapp.id);
      }
      else {
        setSelectedWhatsappId(selectedWhatsappId === "" && defaultWhatsapp ? defaultWhatsapp.id : selectedWhatsappId);
      }
      return;
    }
  }, [modalOpen, selectedWhatsappId, defaultWhatsapp, user]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchUserQueuesIds = async () => {
        try {
          const { data } = await api.get(`/userQueuesIds/${user.id}`);
          setUserQueues(data);
        } catch (exception) {
          console.log("Reopen Ticket Modal Use Effect 1 Exception:", exception);
          toastError(exception);
        }
      };

      fetchUserQueuesIds();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [user.id]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();
    setSelectedWhatsappId("");
  };

  const handleReopenChat = async (event) => {
    try {
      if (!selectedWhatsappId || selectedWhatsappId === undefined) {
        toast.info(i18n.t("newTicketModal.toasts.whatsappNotSelected"));
      } else {
        // ***---- Whatsapp ----***
        if (ticket.type === 0) {
          const params = {
            contactId: ticket.contact.id,
            userId: user.id,
            status: "open",
            isCreatedByUser: true,
            isBotInteractionFinished: true,
            whatsappId: selectedWhatsappId
          };

          const { data: ticketApi } = await api.post("/tickets", params);
          const { data: adminCanViewOtherChats } = await api.get("/settings/adminCanViewOtherChats"); // getting "adminCanViewOtherChats" setting

          const pendingTicketCondition = ticketApi.status === "pending" && (ticketApi.queueId === null || userQueues.includes(ticketApi.queueId));
          const openTicketAndAdminUserCondition = ticketApi.status === "open" && user.profile === "admin" && adminCanViewOtherChats.value === "enabled";
          const openTicketAndCommonUserCondition = ticketApi.status === "open" && ticketApi.userId === user.id;

          // *** User can see Ticket ***
          if ((pendingTicketCondition) || (openTicketAndAdminUserCondition) || (openTicketAndCommonUserCondition)) {
            localStorage.setItem("tabOpenTickets", ticketApi.status);
            setTabOpen(ticketApi.status);
            history.push(`/tickets/${ticketApi.id}`);
          }

          // *** User cannot see Ticket ***
          else {

            // *** Pending Ticket in Another Queue ***
            if (ticketApi.status === "pending") {
              toast.info(`
                ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET")}
                ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET_QUEUE")}
                ${ticketApi.queue?.name}
              `);
            }

            // *** Ticket with Another User ***
            else {
              toast.info(`
                ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET")}
                ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET_USER")}
                ${ticketApi.user?.name}
              `);
            }
          }
        }

        // ***---- Note ----***
        else if (ticket.type === 1) {
          const params = {
            name: ticket.noteName,
            assignToMe: true,
            extraInfo: [],
            userId: user.id,
            status: "open",
            noteCreatedBy: user.id,
            isCreatedByUser: true,
            queueId: null,
            subqueueId: null,
            whatsappId: selectedWhatsappId
          };

          const { data } = await api.post("/notes", params);
          history.push(`/tickets/${data.id}`);
        }

        // ***---- Bot ----***
        else {
          const params = {
            name: ticket.noteName,
            assignToMe: true,
            extraInfo: [],
            userId: user.id,
            status: "open",
            noteCreatedBy: user.id,
            isVreatedByUser: true,
            queueId: null,
            subqueueId: null,
            whatsappId: selectedWhatsappId
          };

          const { data } = await api.post("/botChats", params);
          history.push(`/tickets/${data.id}`);
        }

        setTabOpen("open");
        localStorage.setItem("tabOpenTickets", "open");
      }
    } catch (exception) {
      console.log("Handle Reopen Chat Modal Exception:", exception);
      toastError(exception);
    } finally {
      handleClose();
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <Dialog open={modalOpen} onClose={handleClose}>
      <DialogTitle>
        {i18n.t("contacts.confirmationModal.reopenPrivateChat.title")}
      </DialogTitle>

      <DialogContent dividers>
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel>{i18n.t("newTicketModal.connection")}</InputLabel>
          <Select
            value={selectedWhatsappId}
            onChange={(event) => setSelectedWhatsappId(event.target.value)}
            label={i18n.t("newTicketModal.connection")}
            fullWidth
          >
            <MenuItem value={""}>&nbsp;</MenuItem>
            {whatsAppsList.map((whatsapp) => (
              <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          color="inherit"
          variant="outlined"
          className={classes.floatingButton}
        >
          {i18n.t("newTicketModal.buttons.cancel")}
        </Button>

        <ButtonWithSpinner
          className={classes.floatingButton}
          variant="contained"
          type="button"
          onClick={(event) => handleReopenChat(event)}
          color="primary"
        >
          {i18n.t("newTicketModal.buttons.ok")}
        </ButtonWithSpinner>
      </DialogActions>
    </Dialog>
  );
};

export default ReopenTicketModal;
