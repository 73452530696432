import React, { useEffect } from "react";

import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketAutomaticMessages = ({ dispatch }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { getSocket } = useSocketAuthenticated();



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const socket = getSocket();

    if (socket) {
      const handleAutomaticMessage = (data) => {
        if ((data.action === "update" || data.action === "create") && (`${api.defaults.headers.tenantId.toString()}` === `${data.tenantId.toString()}`)) {
          const transformedAutomaticMessage = {
            id: data.automaticMessage.id,
            name: data.automaticMessage.name,
            body: data.automaticMessage.body,
            keywords: data.automaticMessage.automaticMessageKeywords.map(keyword => keyword.keyword).join(", "),
          };

          dispatch({ type: "UPDATE_AUTOMATIC_MESSAGES", payload: transformedAutomaticMessage });
        }

        if ((data.action === "delete") && (`${api.defaults.headers.tenantId.toString()}` === `${data.tenantId.toString()}`)) {
          dispatch({ type: "DELETE_AUTOMATIC_MESSAGE", payload: data.automaticMessageId });
        }
      }

      socket.on("automaticMessage", handleAutomaticMessage);

      return () => {
        socket.off("automaticMessage", handleAutomaticMessage);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);



  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketAutomaticMessages;
