import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Avatar,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import formatPhoneNumberVCards from '../../utils/formatPhoneNumberVCards';
import toastError from "../../errors/toastError";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles((theme) => ({
  vCardInfo: { color: theme.palette.secondary.ackCheck, userSelect: "none", },
}));

const VcardPreview = ({ contact, numbers, enableHandleNewChat=true }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [selectedContact, setContact] = useState({
    name: "",
    number: 0,
    profilePicUrl: ""
  });

  // ***---- Open Private Chat ----***
  const [openPrivateChatConversationModal, setOpenPrivateChatConversationModal] = useState(false);

  // ***---- Selected WhatsApp ----***
  const { whatsApps: whatsAppsList } = useWhatsApps();
  const [selectedWhatsappId, setSelectedWhatsappId] = useState(null);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (user.whatsapp?.id) setSelectedWhatsappId(user.whatsapp.id);
    else if (whatsAppsList.length === 1) setSelectedWhatsappId(whatsAppsList[0].id);
  }, [user, whatsAppsList]);
  
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          if (numbers !== undefined) {
            let contactObj = {
              name: contact,
              number: formatPhoneNumberVCards(numbers),
              email: ""
            }
            const { data } = await api.post("/contact", contactObj);
            setContact(data);
          }
          else {
            setContact({ name: contact, number: 0, profilePicUrl: "" });
          }
        } catch (error) {
          console.log("VCard Preview Use Effect 1 Error:", error);
          toastError(error);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [contact, numbers]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenPrivateChatConversationModal = () => {
    setOpenPrivateChatConversationModal(true);
  };
  
  const handleClosePrivateChatConversationModal = () => {
    setOpenPrivateChatConversationModal(false);
    setSelectedWhatsappId(null);
  };

  const handleNewChat = async () => {
    if (!selectedWhatsappId || selectedWhatsappId === undefined) {
      toast.info(i18n.t("newTicketModal.toasts.whatsappNotSelected"));
    } else {
      // Contact has shared other contact with number >> starts chat
      if (numbers !== undefined) {
        try {
          const { data: ticket } = await api.post("/tickets", {
            contactId: selectedContact.id,
            userId: user.id,
            status: "open",
            isBotInteractionFinished: true,
            whatsappId: selectedWhatsappId
          });
          history.push(`/tickets/${ticket.id}`);
        } catch (error) {
          console.log("Handle New Chat Error:", error);
          toastError(error);
        }
      }

      // Contact has shared other contact without number >> shows a toast message
      else { toast.info(i18n.t("vCard.info")); }
    }
  }



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      <ConfirmationModal
        title={`${i18n.t("vCard.confirmationModal.title")} ${selectedContact.name}?`}
        open={openPrivateChatConversationModal}
        onClose={handleClosePrivateChatConversationModal}
        onConfirm={handleNewChat}
      >
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel>{i18n.t("newTicketModal.connection")}</InputLabel>
          <Select
            value={selectedWhatsappId}
            onChange={(e) => setSelectedWhatsappId(e.target.value)}
            label={i18n.t("newTicketModal.connection")}
            fullWidth
          >
            <MenuItem value={""}>&nbsp;</MenuItem>
            {whatsAppsList.map((whatsapp) => (
              <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </ConfirmationModal>

      <div style={{ minWidth: "250px" }}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Avatar src={selectedContact.profilePicUrl} />
          </Grid>

          <Grid item xs={9}>
            <Typography
              onClick={selectedContact.number && enableHandleNewChat ? () => handleOpenPrivateChatConversationModal() : () => {}}
              className={classes.vCardInfo}
              style={{ 
                  marginTop: "12px",
                  marginLeft: "10px",
                  cursor: selectedContact.number && enableHandleNewChat ? "pointer" : "default",
                  "&:hover": { textDecoration: selectedContact.number && enableHandleNewChat ? "underline" : "none" },
              }}
              variant="subtitle1"
              gutterBottom
            >
              {selectedContact.name}
            </Typography>
          </Grid>
          
          <Grid item xs={12}>
            <Divider />
            <Button
              fullWidth
              color="inherit"
              onClick={handleOpenPrivateChatConversationModal}
              disabled={!selectedContact.number || !enableHandleNewChat}
            >
              {i18n.t("vCard.button")}
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );

};

export default VcardPreview;